import React, { useState, useEffect } from "react";
import { store } from "../../../../store/store";
import "./index.scss";
import EditIcon from "../../../../assets/svg/edit-icon.svg";
import CommentIcon from "../../../../assets/svg/comment-icon.svg";
import BrokenImage from "../../../../assets/img/BrokenImage.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { getQuickViewBucket } from "../../../../store/actions";
import { Button } from "pyxis-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import { AuthState } from "../../../../store/types";
import axios from "axios";
import { AUDIT_URI, DESIGN_URI, IMAGE_BASE_URL } from "../../../../utilities/paths";
import { detectQueryString, getPreview } from "../../../../utilities/common-function";
import Img from "../../../ImageLoader/ImageLoader";
interface cardData {
  entity_type: string;
  entity_name: string;
  card_type: string;
  timestamp: string;
  entity_id: string;
  commented_by?: string;
  commenter_name?: string;
  approval_request_by?: string;
  approval_status?: string;
  mentioned_users_name?: string;
  text?: string;
  rendered_file?: string;
}

const QuickViewAccess:React.FunctionComponent = () => {
  
  const authState = useSelector((state: {authentication:AuthState})=>state.authentication);
  const { userData , token} = authState;
  const dispatch = useDispatch();
  const [quickViewCards, setQuickViewCards] = useState<cardData[]>([]);
  const [hideButtonLeft, setHideButtonLeft] = React.useState(0);
  const [hideButtonRight, setHideButtonRight] = React.useState(0);
  const [sliderWidth, setSliderWidth] = React.useState(0);
  const getPermissions = store.getState().roles.userPermissions;

  const getUserRole = () => {
    let approver = "grand-approval";
    if (getPermissions.includes(approver)) {
      return "approver";
    } else {
      return "designer";
    }
  };

  const scroll = (scrollOffset) => {
    document.getElementById("quick-access-data").scrollLeft += scrollOffset;
  };
  const getQuickViewCards = async() => {
    let role = getUserRole();
    if (role) {
      dispatch(
        getQuickViewBucket({ role: role }, async (data: any, err: boolean) => {
          if (!err) {
            data.map( async(element,index) => {
              if (element.entity_type == "project") {
                  
                let url = `${DESIGN_URI}/api/project/${element.entity_id}?editor_mode=false`;

                const response = await axios.get(url, {
                    headers: {
                        token: token
                    }
                })
                if (!response.data.error) {
                  const temp = [...data]
                    const previewFileType = (response.data.data.type !== 'image' && response.data.data.type !== 'image_set' && response.data.data.type !== 'gif' && response.data.data.type !== 'variant_set')
                    const preview = previewFileType ? getPreview(response.data.data?.file_type) : !response.data.data.rendered_file.includes("gif") ? response.data.data.rendered_file + (detectQueryString(response.data.data.rendered_file) ? '&w=200' : '?w=200') : response.data.data.rendered_file || ""
                    temp[index].rendered_file = previewFileType ? preview : IMAGE_BASE_URL + preview;
                    setQuickViewCards(temp);
                }
              } else {
                  let url = `${DESIGN_URI}/api/hp-project/${element.entity_id}?with_default_json=false`;

                  const response = await axios.get(url, {
                      headers: {
                          token: token
                      }
                  })
                  if (!response.data.error) {
                    const temp = [...data]
                    temp[index].rendered_file = response.data.data.rendered_file ? IMAGE_BASE_URL + response.data.data.rendered_file + '?q=40&auto=format&h=220&w=330' : "";
                    setQuickViewCards(temp);
                  }
              }
              if (element.mentioned_users_name && element.mentioned_users_name.length > 0) {
                  element.mentioned_users_name.forEach((m: string) => {
                      element.text = element.text.replaceAll(m,
                          "<span class='name'>@" +
                          m + "</span>"
                      );
                  });
                  setQuickViewCards(data);
                }

              return element;
          })
            setQuickViewCards(data);
          }
        })
      );
    }
  };
  const onHScroll = () => {
    const el = document.getElementById(`quick-access-data`).scrollLeft;
    if (el > 10) {
      setHideButtonLeft(1);
    } else {
      setHideButtonLeft(0);
    }
    if (el < sliderWidth) {
      setHideButtonRight(1);
    } else {
      setHideButtonRight(0);
    }
  };

  useEffect(() => {
    if (quickViewCards.length > 0) {
      setSliderWidth(
        document.getElementById("quick-access-data").scrollWidth -
          document.getElementById("quick-access-data").offsetWidth
      );
      const el = document.getElementById(`quick-access-data`).scrollLeft;
      if (el < sliderWidth) {
        setHideButtonRight(1);
      } else {
        setHideButtonRight(0);
      }
    }
    
  })
  const designClickEvent = (quickViewCard) => {
      const eventData = {};
      eventData["event_meta"] = {};
      eventData["event_meta"]["entity_id"] = quickViewCard.entity_id.toString();
      eventData["event_meta"]["entity_type"] = quickViewCard.entity_type;
      eventData["event_meta"]["user_id"] = userData.userId;
      eventData["event_name"] = quickViewCard.card_type === "comment" ? "comment_clicked" : "design_clicked";
      axios
      .post(AUDIT_URI + "/event", eventData)
      .then((response) => {
          if (response.status === 200) {
          return true;
          }
      })
      .catch((e) => {
        console.log("User Click event has an error");
      });
  };
  useEffect(() => {
    getQuickViewCards();
  }, [userData.userId,getPermissions]);

  return (
    <div className="quick-access-view">
    {quickViewCards.length > 0 && (
      <>
      <div className="quick-access-title">
        <h3>Your Projects:</h3>
      </div>
      <div className="quick-view-section" id="quick-view-section">
        <Button
          className="arrows"
          id="left-button"
          onClick={() => scroll(-200)}
          style={{opacity: hideButtonLeft}}
          disabled={!hideButtonLeft}
        >
          <FontAwesomeIcon icon={faChevronLeft} size="3x" />
        </Button>
          
        <div className="quick-access-data" id="quick-access-data" onScroll={() => {onHScroll()}}>
          {quickViewCards.map((quickViewCard, index) => {
            const {
              entity_type,
              entity_name,
              card_type,
              timestamp,
              entity_id,
              commented_by,
              commenter_name,
              approval_request_by,
              approval_status,
              mentioned_users_name,
              text,
              rendered_file,
            } = quickViewCard;
            const param =
              card_type == "comment"
                ? `banner=${entity_id}&quick_view=true`
                : `banner=${entity_id}`;
            return (
              
                <div key={index} className="quick-access-view-card">
                  <Link
                to={
                  entity_type === "project"
                    ? `/cocreate/banner-detail/?` + param
                    : `/cocreate/creative-automation/detail/?` + param
                }
                className="view-link"
                onClick={() => {
                  designClickEvent(quickViewCard);
                }}
              >
                  <div className="image">
                    <Img className="projectImg" 
                      src={rendered_file} style={{objectFit:'scale-down',objectPosition:'center center'}}
                      />
                  </div>
                  <div className="project-title">
                    <h3>{entity_name || "DEMO"}</h3>
                  </div>
                  <div className="text">
                    {card_type == "approval" ? (
                      approval_status == "pending" ? (
                        <>
                          <div className="icon">
                            <img className="editIcons" src={EditIcon} />
                          </div>
                          <div className="commentText">
                            <span className="mentioned-name">
                              {approval_request_by}
                            </span>{" "}
                            is waiting for your approval
                          </div>
                        </>
                      ) : approval_status == "rejected" ? (
                        <>
                          <div className="icon">
                            <img className="editIcons" src={EditIcon} />
                          </div>
                          <div className="commentText">
                            
                            Your approval request is <span className="red">Rejected</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="icon">
                            <img className="editIcons" src={EditIcon} />
                          </div>
                          <div className="commentText">
                            
                            Your approval request is <span className="green">Approved</span>
                          </div>
                        </>
                      )
                    ) : card_type == "modification" ? (
                      <div className="commentText">
                        <span className="icon">
                          <img className="editIcons" src={EditIcon} />
                        </span>
                        Last Edited by you
                      </div>
                    ) : (
                      <>
                        <div className="icon">
                          <img className="editIcons" src={CommentIcon} />
                        </div>
                        <div className="commentText">
                          {mentioned_users_name.length > 0 ? "Mentioned" : "Commented"} by <span className="name">{commented_by === userData.userId ? "you" :commenter_name}</span>
                        </div>
                      </>
                    )}
                    
                    <div className="timestamp">
                      {timestamp && moment(timestamp).fromNow()}
                    </div>
                    {card_type == "comment" && (
                      <div className="commentOnProject" 
                      dangerouslySetInnerHTML={{
                        __html: '"' + text + '"'
                      }}></div>
                    )}
                  </div>
                  </Link>
                </div>
            );
          })}
        </div>
        <Button
          className="arrows"
          id="right-button"
          onClick={() => scroll(200)}
          style={{opacity: hideButtonRight}}
          disabled={!hideButtonRight}
        >
          <FontAwesomeIcon icon={faChevronRight} size="3x" />
        </Button>
      </div> 
      </>)}
    </div>
  );
};

export default QuickViewAccess;
