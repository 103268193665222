import {
  SendForApproval,
  SEND_FOR_APPROVAL,
  SetApprovalLoader,
  SET_APPROVAL_LOADER,
  GetStatusSummary,
  GET_STATUS_SUMMARY,
  GetApprovalStatus,
  GET_APPROVAL_STATUS,
  SetApprovalStatus,
  SET_APPROVAL_STATUS,
  SetIsSentForApproval,
  SET_IS_SENT_FOR_APPROVAL,
  DuplicateApprovalRound,
  DUPLICATE_APPROVAL_ROUND,
  DeleteApprovalRound,
  DELETE_APPROVAL_ROUND,
  EditApprovalRound,
  EDIT_APPROVAL_ROUND,
  ChangeApprovalRound,
  CHANGE_APPROVAL_ROUND,
  AddApprovalRound,
  ADD_APPROVAL_ROUND,
  AppendApprovalRound,
  APPEND_APPROVAL_ROUND,
  SetApprovalComments,
  SET_APPROVAL_COMMENTS,
  FETCH_APPROVAL_COMMENTS,
  FetchApprovalComments,
  SendApprovalComment,
  SEND_APPROVAL_COMMENT,
  AddApprovalComment,
  ADD_APPROVAL_COMMENT,
  EditApprovalComment,
  EDIT_APPROVAL_COMMENT,
  DeleteApprovalComment,
  DELETE_APPROVAL_COMMENT,
  ChangeApprovalComment,
  CHANGE_APPROVAL_COMMENT,
  SetApprovalStatusSummary,
  SET_APPROVAL_SUMMARY,
  FETCH_PENDING_APPROVALS,
  SetPendingApproval,
  SET_PENDING_APPROVALS,
  SetPendingEntities,
  SET_PENDING_ENTITIES,
  AddUserToApprovalRound,
  ADD_USER_TO_APPROVAL_ROUND,
  DeleteUserFromApprovalRound,
  DELETE_USER_FROM_APPROVAL_ROUND,
  MarkApprovalStatus,
  MARK_APPROVAL_STATUS,
  FetchMyApprovalStatus,
  FETCH_MY_APPROVAL_STATUS,
  CANCEL_APPROVAL_REQUEST,
  CancelApprovalRequest,
  ContinueApproval,
  CONTINUE_APPROVAL,
  FetchPendingApprovalCount,
  FETCH_PENDING_APPROVAL_COUNT,
  SetPendingApprovalCount,
  SET_PENDING_APPROVAL_COUNT,
  SetActiveApprovalStatus,
  SET_ACTIVE_APPROVAL_STATUS,
  FETCH_APPROVALS_LIST,
  FetchApprovalsList,
  SetActiveApprovalEntityType,
  SET_ACTIVE_APPROVAL_ENTITY_TYPE,
  ReSendForApproval,
  RESEND_FOR_APPROVAL,
  SetCommentsResolutionType,
  SET_COMMENTS_RESOLUTION_TYPE,
  FolderApproval,
  FOLDER_APPROVAL
} from "../types";

export const sendForApproval = (
  payload: { entity_id: number; workflow_id: number; note: string; entity_type: string },
  callback?: Function
): SendForApproval => ({
  type: SEND_FOR_APPROVAL,
  payload,
  callback,
});

export const folderApproval = (
  payload: { entity_ids: number[]; workflow_id: number; note: string; entity_type: string },
  callback?: Function
): FolderApproval => ({
  type: FOLDER_APPROVAL,
  payload,
  callback,
});

export const setApprovalLoader = (payload: boolean): SetApprovalLoader => ({
  type: SET_APPROVAL_LOADER,
  payload: payload,
});

export const getStatusSummary = (
  payload: {
    ids: number[];
    entity_type: string;
  },
  callback?: Function
): GetStatusSummary => ({
  type: GET_STATUS_SUMMARY,
  payload,
  callback,
});
export const setStatusSummary = (payload: any): SetApprovalStatusSummary => ({
  type: SET_APPROVAL_SUMMARY,
  payload,
});

export const getApprovalStatus = (
  payload: {
    entity_id: number;
    entity_type: string;
  },
  callback?: Function
): GetApprovalStatus => ({
  type: GET_APPROVAL_STATUS,
  payload,
  callback,
});

export const setApprovalStatus = (payload: any): SetApprovalStatus => ({
  type: SET_APPROVAL_STATUS,
  payload: payload,
});

export const setIsSentForApproval = (payload: boolean): SetIsSentForApproval => ({
  type: SET_IS_SENT_FOR_APPROVAL,
  payload: payload,
});

export const duplicateApprovalRound = (
  payload: { entity_id: number; round_id: number },
  callback?: Function
): DuplicateApprovalRound => ({
  type: DUPLICATE_APPROVAL_ROUND,
  payload,
  callback,
});

export const deleteApprovalRound = (
  payload: { entity_id: number; round_id: number },
  callback?: Function
): DeleteApprovalRound => ({
  type: DELETE_APPROVAL_ROUND,
  payload,
  callback,
});

export const editApprovalRound = (
  payload: { entity_id: number; round_id: number; round: any },
  callback?: Function
): EditApprovalRound => ({
  type: EDIT_APPROVAL_ROUND,
  payload,
  callback,
});

export const changeApprovalRound = (
  round: any,
  roundIndex: number
): ChangeApprovalRound => ({
  type: CHANGE_APPROVAL_ROUND,
  payload: {
    round,
    roundIndex,
  },
});

export const addApprovalRound = (
  payload: { entity_id: number; round: any },
  callback?: Function
): AddApprovalRound => ({
  type: ADD_APPROVAL_ROUND,
  payload,
  callback,
});

export const appendApprovalRound = (payload: any): AppendApprovalRound => ({
  type: APPEND_APPROVAL_ROUND,
  payload,
});

export const setApprovalComments = (payload: any[]): SetApprovalComments => ({
  type: SET_APPROVAL_COMMENTS,
  payload,
});

export const fetchApprovalComments = (
  payload: { entity_id: number; page: number; entity_type: string; order_by?: string; child_id: number; resolution_status?: number },
  callback?: Function
): FetchApprovalComments => ({
  type: FETCH_APPROVAL_COMMENTS,
  payload: { ...payload, page_size: 100 },
  callback,
});

export const sendApprovalComment = (
  payload: { entity_id: number; comment: string; entity_type: string; },
  callback?: Function
): SendApprovalComment => ({
  type: SEND_APPROVAL_COMMENT,
  payload,
  callback,
});

export const addApprovalComment = (payload: any): AddApprovalComment => ({
  type: ADD_APPROVAL_COMMENT,
  payload,
});

export const setCommentsResolutionType = (payload: any): SetCommentsResolutionType => ({
  type: SET_COMMENTS_RESOLUTION_TYPE,
  payload,
});


export const editApprovalComment = (
  payload: { comment_id: number; comment: string },
  callback?: Function
): EditApprovalComment => ({
  type: EDIT_APPROVAL_COMMENT,
  payload,
  callback,
});

export const deleteApprovalComment = (
  payload: number,
  callback?: Function
): DeleteApprovalComment => ({
  type: DELETE_APPROVAL_COMMENT,
  payload,
  callback,
});

export const changeApprovalComment = (
  comment: any,
  commentIndex: number
): ChangeApprovalComment => ({
  type: CHANGE_APPROVAL_COMMENT,
  payload: {
    comment,
    commentIndex,
  },
});

export const fetchPendingApprovals = (
  payload: {
    page: number;
    user_id: string;
    page_size?: number;
    product_id?: string;
  },
  callback?: Function
) => ({
  type: FETCH_PENDING_APPROVALS,
  payload: { ...payload, page_size: 10, product_id: "1" },
  callback,
});

export const setPendingApprovals = (payload: any): SetPendingApproval => ({
  type: SET_PENDING_APPROVALS,
  payload,
});

export const setPendingEntities = (payload: any): SetPendingEntities => ({
  type: SET_PENDING_ENTITIES,
  payload,
});

export const addUserToApprovalRound = (
  payload: { entity_id: number; roundId: number; userId: string },
  callback?: Function
): AddUserToApprovalRound => ({
  type: ADD_USER_TO_APPROVAL_ROUND,
  payload,
  callback,
});
export const deleteUserFromApprovalRound = (
  payload: { entity_id: number; roundId: number; userId: string },
  callback?: Function
): DeleteUserFromApprovalRound => ({
  type: DELETE_USER_FROM_APPROVAL_ROUND,
  payload,
  callback,
});

export const markApprovalStatus = (
  payload: { userId: string; entity_id: number; status: string; entity_type: string; },
  callback?: Function
): MarkApprovalStatus => ({
  type: MARK_APPROVAL_STATUS,
  payload,
  callback,
});

export const fetchMyApprovalStatus = (
  payload: {
    entity_id: number | number[];
    user_id: string | string[];
    entity_type: string;
  },
  callback?: Function
): FetchMyApprovalStatus => ({
  type: FETCH_MY_APPROVAL_STATUS,
  payload,
  callback,
});

export const cancelApprovalRequest = (
  payload: { entity_id: number; entity_type: string; },
  callback?: Function
): CancelApprovalRequest => ({
  type: CANCEL_APPROVAL_REQUEST,
  payload,
  callback,
});

export const continueApproval = (
  payload: { entity_id: number; entity_type: string; },
  callback?: Function
): ContinueApproval => ({
  type: CONTINUE_APPROVAL,
  payload,
  callback,
});

export const fetchPendingApprovalCount = (
  payload: { user_id: string; product_id: string },
  callback?: Function
): FetchPendingApprovalCount => ({
  type: FETCH_PENDING_APPROVAL_COUNT,
  payload,
  callback,
});

export const setPendingApprovalCount = (payload: number): SetPendingApprovalCount => ({
  type: SET_PENDING_APPROVAL_COUNT,
  payload
})

export const setActiveApprovalStatus = (payload: string): SetActiveApprovalStatus => ({
  type: SET_ACTIVE_APPROVAL_STATUS,
  payload
})

export const fetchApprovalsList = (payload: {
  page: number;
  page_size?: number;
  entity_type?: string;
  status?: string;
}, callback?: Function): FetchApprovalsList => ({
  type: FETCH_APPROVALS_LIST,
  payload: { ...payload, page_size: payload.page_size || 20 },
  callback
})

export const setActiveApprovalEntityType = (payload: string | null): SetActiveApprovalEntityType => ({
  type: SET_ACTIVE_APPROVAL_ENTITY_TYPE,
  payload
})

export const reSendForApproval = (payload: { entity_id: number, entity_type: string; }, callback?: Function): ReSendForApproval => ({
  type: RESEND_FOR_APPROVAL,
  payload,
  callback
})