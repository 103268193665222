import React, { useEffect } from "react";
import "./index.scss";
import PromptingBackground from "../../assets/img/PromptingBackground.png";
import { useParams } from "react-router-dom";
import { Input } from "antd";
import AdvancePromptingCard from "./AdvancePromptingCard";
import SimplePromptingCard from "./SimplePromptingCard";
const { TextArea } = Input;

export default function SimplePrompting() {
  const params = useParams<any>();

  return (
    <div className='main'>
      <div
        className='prompting-background'
        style={{ backgroundImage: `url(${PromptingBackground})` }}
      >
        {params.type != "simple_prompting" ? (
          <AdvancePromptingCard />
        ) : (
          <SimplePromptingCard />
        )}
      </div>
    </div>
  );
}
