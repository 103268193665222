import { openToast } from './../../../Toasts';
import { fabric } from 'fabric';
import { ShapeProps, TextProps, ImageProps, FolderProps } from '../../../ProjectContext/types';
import FontFaceObserver from 'fontfaceobserver';
import { NODE_TYPE, LAYER_TYPE } from './node-type';
import {
  makeBlurFilter,
  makeBrightnessFilter,
  makeContrastFilter,
  makeSaturationFilter,
  makeTintFilter,
  makeGrayscaleFilter,
  makeBlendColorFilter,
} from '../../RightMenu/ItemTray/Elements/AdjustmentsTray/utils';
import { v4 } from 'uuid';
import {
  ExtendedFabricImage,
  ExtendedFabricCircle,
  ExtendedFabricRect,
  ExtendedFabricTextbox,
  FabricGroupExtended,
  ExtendedFabricPath,
  ExtendedFabricObject,
} from '../../shared/types';
import { customizedKeysArr, setTextBoxEvents } from '../../../ConversionContext/ConversionContext';
import { IMAGE_BASE_URL } from '../../../utilities/paths';
import HeartShape from '../../../assets/img/heart.svg';

import axios from 'axios';
import { enableEdit } from '../../../utilities/contants';
import { store } from '../../../store/store';

const loadPattern = (src: string, object: fabric.Object, resolve: (value?: unknown) => void) => {
  fabric.util.loadImage(src, image => {
    object.set(
      'fill',
      new fabric.Pattern({
        source: image,
        repeat: 'no-repeat',
        crossOrigin: 'anonymous',
      })
    );
    // hack temp fix for object todataurl getting tainted canvas error
    object.clone((clone: ExtendedFabricObject) => {
      (clone as ExtendedFabricObject).set({ id: v4(), layerType: LAYER_TYPE.SHAPE });
      resolve(clone);
    }, customizedKeysArr);
  });
};

const getFilter = (filterObj: any) => {
  switch (filterObj.type) {
    case 'Blur':
      return makeBlurFilter(filterObj.blur);
    case 'HueRotation':
      return makeTintFilter(filterObj.rotation);
    case 'Brightness':
      return makeBrightnessFilter(filterObj.brightness);
    case 'Contrast':
      return makeContrastFilter(filterObj.contrast);
    case 'Saturation':
      return makeSaturationFilter(filterObj.saturation);
    case 'Grayscale':
      return makeGrayscaleFilter();
    case 'BlendColor':
      return makeBlendColorFilter(filterObj.color, filterObj.alpha, filterObj.mode);
  }
};

export const getCircle = ({
  x,
  y,
  width,
  height,
  rotation = 0,
  color,
  scaleX = 1,
  scaleY = 1,
  opacity = 1,
  exportedAsset = '',
  name = '',
  id,
  layerType,
  stroke,
  strokeWidth,
  strokeUniform
}: ShapeProps) => {
  return new Promise(res => {
    const circle = new fabric.Circle({
      left: x - (width * scaleX) / 2 + width / 2,
      top: y - (+height * scaleY) / 2 + +height / 2,
      angle: rotation,
      fill: color,
      opacity,
      lockScalingFlip: true,
      radius: (width * scaleX) / 2,
      name,
      stroke,
      strokeWidth,
      strokeUniform
    });
    if (exportedAsset && exportedAsset.length > 0) {
      loadPattern(exportedAsset, circle, res);
      return;
    }
    (circle as ExtendedFabricCircle).set({
      id: v4(),
      layerType: LAYER_TYPE.SHAPE,
    });
    res(circle);
  });
};

export const getRectangle = ({
  x,
  y,
  width,
  height,
  rotation = 0,
  color,
  scaleX = 1,
  scaleY = 1,
  opacity = 1,
  exportedAsset = '',
  name = '',
  id,
  layerType,
  isBackground = false,
  stroke = '#000000',
  strokeWidth = 0,
  strokeUniform
}: ShapeProps) => {
  return new Promise(res => {
    const rect = new fabric.Rect({
      left: x - (width * scaleX) / 2 + width / 2,
      top: y - (+height * scaleY) / 2 + +height / 2,
      width: width * scaleX,
      height: +height * scaleY,
      angle: rotation,
      fill: color,
      opacity,
      lockScalingFlip: true,
      name,
      stroke,
      strokeWidth,
      strokeUniform
    });
    if (exportedAsset && exportedAsset.length > 0) {
      loadPattern(exportedAsset, rect, res);
      return;
    }
    (rect as ExtendedFabricRect).set({ id: v4(), layerType: LAYER_TYPE.SHAPE, isBackground });
    res(rect);
  });
};

export const getPolygon = ({
  x,
  y,
  width,
  height,
  rotation = 0,
  color,
  scaleX = 1,
  scaleY = 1,
  opacity = 1,
  exportedAsset = '',
  name = '',
  id,
  layerType,
  isBackground = false,
  stroke,
  strokeWidth,
  points,
  strokeUniform
}: ShapeProps) => {
  return new Promise(res => {
    const star = new fabric.Polygon(points || [], {
      left: x - (width * scaleX) / 2 + width / 2,
      top: y - (+height * scaleY) / 2 + +height / 2,
      width: width * scaleX,
      height: +height * scaleY,
      angle: rotation,
      fill: color,
      opacity,
      lockScalingFlip: true,
      name,
      stroke,
      strokeWidth,
      strokeUniform
    });
    if (exportedAsset && exportedAsset.length > 0) {
      loadPattern(exportedAsset, star, res);
      return;
    }
    (star as any).set({
      id: v4(), layerType: LAYER_TYPE.SHAPE, isBackground,
      scaleY: (height as number) / (star?.height as number),
      scaleX: (width as number) / (star?.width as number)
    });
    res(star);
  });
};

export const getHeart = ({
  x,
  y,
  width,
  height,
  rotation = 0,
  color,
  scaleX = 1,
  scaleY = 1,
  opacity = 1,
  exportedAsset = '',
  name = '',
  id,
  layerType,
  isBackground = false,
  stroke,
  strokeWidth,
  strokeUniform
}: ShapeProps) => {
  return new Promise(res => {
    const heart = new fabric.Path('M25.3947 2.12177C24.7223 1.44911 23.924 0.915505 23.0454 0.551444C22.1668 0.187383 21.225 0 20.2739 0C19.3229 0 18.3811 0.187383 17.5025 0.551444C16.6239 0.915505 15.8256 1.44911 15.1532 2.12177L13.7579 3.51714L12.3625 2.12177C11.0044 0.763676 9.16243 0.00070395 7.24179 0.000703964C5.32115 0.000703978 3.47917 0.763676 2.12107 2.12177C0.762972 3.47987 1.43099e-08 5.32185 0 7.24249C-1.43099e-08 9.16313 0.762972 11.0051 2.12107 12.3632L3.51643 13.7586L13.7579 24L23.9993 13.7586L25.3947 12.3632C26.0673 11.6909 26.6009 10.8926 26.965 10.0139C27.329 9.13531 27.5164 8.19356 27.5164 7.24249C27.5164 6.29142 27.329 5.34967 26.965 4.47104C26.6009 3.59241 26.0673 2.79412 25.3947 2.12177V2.12177Z', {
      left: x - (width * scaleX) / 2 + width / 2,
      top: y - (+height * scaleY) / 2 + +height / 2,
      width: width * scaleX,
      height: +height * scaleY,
      angle: rotation,
      fill: color,
      opacity,
      lockScalingFlip: true,
      name,
      stroke,
      strokeWidth,
      scaleY,
      scaleX,
      strokeUniform
    });

    if (exportedAsset && exportedAsset.length > 0) {
      loadPattern(exportedAsset, heart, res);
      return;
    }
    (heart as any).set({
      id: v4(), layerType: LAYER_TYPE.SHAPE, isBackground,
      scaleY: (height as number) / (heart?.height as number),
      scaleX: (width as number) / (heart?.width as number)
    });
    res(heart);
  });
};

export const getTriangle = ({
  x,
  y,
  width,
  height,
  rotation = 0,
  color,
  scaleX = 1,
  scaleY = 1,
  opacity = 1,
  exportedAsset = '',
  name = '',
  id,
  layerType,
  isBackground = false,
  stroke,
  strokeWidth,
  strokeUniform
}: ShapeProps) => {
  return new Promise(res => {
    const triangle = new fabric.Triangle({
      left: x - (width * scaleX) / 2 + width / 2,
      top: y - (+height * scaleY) / 2 + +height / 2,
      width: width * scaleX,
      height: +height * scaleY,
      angle: rotation,
      fill: color,
      opacity,
      lockScalingFlip: true,
      name,
      stroke,
      strokeWidth,
      strokeUniform
    });
    if (exportedAsset && exportedAsset.length > 0) {
      loadPattern(exportedAsset, triangle, res);
      return;
    }
    (triangle as any).set({ id: v4(), layerType: LAYER_TYPE.SHAPE, isBackground });
    res(triangle);
  });
};

const getCustomShape = ({ name, ...rest }: ShapeProps) => {
  if (name?.toLowerCase().includes('circle')) {
    return getCircle(rest);
  }
  if (name?.toLowerCase().includes('heart')) {
    return getHeart(rest);
  }
  if (name?.toLowerCase().includes('star')) {
    return getPolygon(rest);
  }
  if (name?.toLowerCase().includes('triangle')) {
    return getTriangle(rest);
  }
  if (name?.toLowerCase().includes('hexagon')) {
    return getPolygon(rest);
  }
  if (name?.toLowerCase().includes('pentagon')) {
    return getPolygon(rest);
  }
  return getRectangle(rest);
};

export const createTextbox = ({
  x,
  y,
  width,
  height,
  text,
  textAlign,
  fontSize,
  rotation = 0,
  color,
  scaleX = 1,
  scaleY = 1,
  opacity = 1,
  font,
  fauxBold,
  fauxItalic,
  lineHeight,
  letterSpacing = 0,
  underline = false,
  name,
  id,
  layerType,
  isCustomFont,
}: TextProps) => {
  const textbox = new fabric.Textbox(text, {
    left: x,
    top: y,
    width: width / scaleX,
    height: isNaN(+height) ? 100 : +height,
    scaleX,
    scaleY,
    angle: 0,
    opacity: opacity,
    fontSize,
    fontWeight: 'normal',
    fontFamily: font,
    fontStyle: 'normal',
    lineHeight: isNaN(lineHeight / fontSize) ? 1 : lineHeight / fontSize,
    underline,
    textAlign: textAlign.toLowerCase(),
    charSpacing: (letterSpacing / fontSize) * 1000,
    splitByGrapheme: true,
    fill: color,
    name,
    stroke: '#ffffff',
    strokeWidth: 0,
  });
  (textbox as ExtendedFabricTextbox).set({ id: v4(), layerType: LAYER_TYPE.TEXT, isCustomFont });
  textbox.rotate(rotation);
  setTextBoxEvents(textbox);
  return textbox;
};

export const getTextbox = ({ font, isCustomFont, ...rest }: TextProps) => {
  return new Promise(res => {
    const fontData = {
      weight: 400,
      src: `url('${IMAGE_BASE_URL}font_files/${isCustomFont ? (rest as any).productId : 'webfonts'}/${font.replace(/ /g, '')}.ttf')`,
    };
    const obs = new FontFaceObserver(font, fontData);
    // @ts-ignore
    const ff = new FontFace(font, fontData.src);
    // @ts-ignore
    ff.load()
      // @ts-ignore
      .then(f => {
        // @ts-ignore
        document.fonts.add(f);
      })
      .catch((err: any) => console.log(`Missing Font: ${font}`, err));
    obs
      .load()
      .then(() => res(createTextbox({ font, isCustomFont, ...rest })))
      .catch((err) => {
        if (!(rest as any).dontShowToast) {
          openToast('error', `Missing Font: ${font}`);
          openToast('info', `Loading Font: Helvetica-Black`);
        }
        res(getTextbox({ font: 'Helvetica-Black', isCustomFont: false, ...rest }));
      });
  });
};

const getSvgFromObject = (
  svgObj: fabric.Group | fabric.Path,
  res: Function,
  flipX: boolean,
  flipY: boolean,
  layerType: string | undefined,
  cropX: number,
  cropY: number,
  cropWidth: number | undefined,
  cropHeight: number | undefined,
  exportedAsset: string,
  id: string | undefined,
  name: string
) => {
  if (svgObj && svgObj.type === 'path') {
    fabric.Path.fromObject(svgObj, (obj: ExtendedFabricPath) => {
      obj.set({
        id: v4(),
        layerType: LAYER_TYPE.ELEMENTS,
        flipX,
        flipY,
        isSvg: true,
        name,
        exportedAsset,
      });
      if (cropWidth && cropWidth > 0 && cropHeight && cropHeight > 0) {
        obj.set({ cropWidth, cropHeight, cropX, cropY });
      }
      res(obj);
      return;
    });
  }
  fabric.Group.fromObject(svgObj, obj => {
    (obj as FabricGroupExtended).set({
      id: v4(),
      layerType: LAYER_TYPE.ELEMENTS,
      flipX,
      flipY,
      isSvg: true,
      name,
      exportedAsset,
    });
    if (cropWidth && cropWidth > 0 && cropHeight && cropHeight > 0) {
      (obj as FabricGroupExtended).set({ cropWidth, cropHeight, cropX, cropY });
    }
    res(obj);
  });
};

export const getImage = ({
  x,
  y,
  exportedAsset,
  width,
  height,
  scaleX = 1,
  scaleY = 1,
  rotation = 0,
  opacity = 1,
  filters = [],
  svgObj,
  isSvg = false,
  name = 'New Image',
  id,
  layerType,
  flipX = false,
  flipY = false,
  cropX = 0,
  cropY = 0,
  naturalHeight,
  naturalWidth,
  widthInPixels,
  heightInPixels,
  cropWidth,
  cropHeight,
  stroke = '#ffffff',
  strokeWidth = 0,
  isBackground = false,
  colorsMap,
  selectable = true,
}: any) => {

  return new Promise(res => {
    if (isSvg || !!svgObj) {
      getSvgFromObject(
        svgObj as fabric.Group | fabric.Path,
        res,
        flipX,
        flipY,
        layerType,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        exportedAsset,
        id,
        name,
      );
    } else if (exportedAsset.endsWith('.svg')) {
      loadSvg(
        {
          x,
          y,
          exportedAsset,
          width,
          height,
          scaleX,
          scaleY,
          rotation,
          opacity,
          filters,
          name,
          layerType,
          flipX,
          flipY,
          cropX,
          cropY,
          naturalHeight,
          naturalWidth,
          widthInPixels,
          heightInPixels,
          isBackground,
          colorsMap,
          selectable,
        },
        res
      );
    } else {
      fabric.Image.fromURL(
        exportedAsset,
        image => {
          if (image === undefined) return;
          const aspectRatio = (image.width || 1) / (image.height || 1);
          (image as ExtendedFabricImage).set({
            left: x - (width * scaleX) / 2 + width / 2,
            top: y - ((width / aspectRatio) * scaleX) / 2 + width / aspectRatio / 2,
            scaleX: 1,
            scaleY: 1,
            angle: rotation,
            opacity,
            crossOrigin: 'anonymous',
            name,
            flipX,
            flipY,
            id: v4(),
            layerType: LAYER_TYPE.IMAGE,
            naturalHeight,
            naturalWidth,
            lockScalingFlip: true,
            stroke,
            strokeWidth,
            isBackground,
          });

          const validFilters = filters.filter((fObj: any) => fObj.mode !== 'normal');
          if (validFilters.length > 0) {
            validFilters.forEach((filObj: any) => image.filters?.push(getFilter(filObj)));
            image.applyFilters();
          }

          if (!!naturalWidth || !!naturalHeight || !!widthInPixels || !!heightInPixels) {
            (image as ExtendedFabricImage).set({
              scaleX: (width * scaleX) / (widthInPixels as number),
              scaleY: ((height as number) * scaleY) / (heightInPixels as number),
              cropX,
              cropY,
              width: widthInPixels,
              height: heightInPixels,
              naturalWidth: naturalWidth || widthInPixels,
              naturalHeight: naturalHeight || heightInPixels,
            });
          } else {
            image.scaleToWidth(width * scaleX);
            image.scaleToHeight((width * scaleX) / aspectRatio);
          }
          res(image);
        },
        { crossOrigin: 'anonymous' }
      );
    }
  });
};

const loadSvg = async (
  {
    x,
    y,
    exportedAsset,
    width,
    height,
    scaleX = 1,
    scaleY = 1,
    rotation = 0,
    opacity = 1,
    filters = [],
    name = 'SVG',
    layerType,
    flipX = false,
    flipY = false,
    cropX = 0,
    cropY = 0,
    naturalHeight,
    naturalWidth,
    widthInPixels,
    heightInPixels,
    isBackground = false,
    colorsMap,
    selectable,
    id,
  }: any,
  res: Function
) => {
  const { url, img: svgXML, blob } = await getSvg(exportedAsset);
  fabric.Image.fromURL(
    url,
    image => {
      if (image === undefined) return;
      (image as any).set({
        left: x - (width * scaleX) / 2 + width / 2,
        top: y - (height * Math.abs(scaleY)) / 2 + height / 2,
        scaleX: 1,
        scaleY: 1,
        opacity,
        crossOrigin: 'anonymous',
        name,
        flipX: flipX || scaleX < 0,
        flipY: flipY || scaleY < 0,
        id: id || v4(),
        naturalHeight,
        naturalWidth,
        lockScalingFlip: true,
        layerType: !layerType ? LAYER_TYPE.ELEMENTS : layerType,
        isBackground,
        svgXML,
        isSvg: true,
        blob,
        selectable,
      });

      if (!!naturalWidth && !!naturalHeight && !!widthInPixels && !!heightInPixels) {
        (image as ExtendedFabricImage).set({
          scaleX: (width * scaleX) / (widthInPixels as number),
          scaleY: ((height as number) * scaleY) / (heightInPixels as number),
          cropX,
          cropY,
          width: widthInPixels,
          height: heightInPixels,
          naturalWidth,
          naturalHeight,
        });
      } else {
        image.scaleY = (height * Math.abs(scaleY)) / (image as any).height;
        // fix to load scaled svg since only getting used first time
        image.scaleX = image.scaleY;
      }
      rotateS(image, rotation, (width * scaleX) / 2 - width / 2, (height * Math.abs(scaleY)) / 2 - height / 2);

      // setTimeout(() => {
      filters.forEach((filObj: any) => image.filters?.push(getFilter(filObj)));
      image.applyFilters();
      image?.canvas?.requestRenderAll();
      // }, 0);
      res(image);
    },
    { crossOrigin: 'anonymous' }
  );
};

const rotateS = (o: any, angle: number, offsetX: number, offsetY: number) => {
  const centerBeforeRotation = o.getCenterPoint();
  o.set('angle', angle);
  const centerAfterRotation = o.getCenterPoint();
  o.left += offsetX !== 0 ? centerBeforeRotation.x - centerAfterRotation.x : 0;
  o.top += offsetY !== 0 ? centerBeforeRotation.y - centerAfterRotation.y : 0;
};

const getSvg = async (url: string) => {
  const res = await axios.get(url);
  const parser = new DOMParser();
  const img = parser.parseFromString(res.data, 'image/svg+xml').querySelector('svg');
  const viewBox = img?.getAttribute('viewBox')?.split(' ') || ['0', '0', '1000', '1000'];
  img?.setAttribute('width', viewBox[2]);
  img?.setAttribute('height', viewBox[3]);
  setBlackColor(img);
  const string = new XMLSerializer().serializeToString(img as any);
  const blob = new Blob([string], { type: 'image/svg+xml' });
  const src = URL.createObjectURL(blob);
  return { url: src, img, blob };
};

export const getSvg1 = async (url: string) => {
  return new Promise(async (resolve) => {
    const res = await axios.get(url);
    const parser = new DOMParser();
    const img = parser.parseFromString(res.data, 'image/svg+xml').querySelector('svg');
    const viewBox = img?.getAttribute('viewBox')?.split(' ') || ['0', '0', '1000', '1000'];
    img?.setAttribute('width', viewBox[2]);
    img?.setAttribute('height', viewBox[3]);
    setBlackColor(img);
    // const string = new XMLSerializer().serializeToString(img as any);
    // const blob = new Blob([string], { type: 'image/svg+xml' });
    // const src = URL.createObjectURL(blob);
    resolve({ img });
  });
};

const setBlackColor = (svg: any) => {
  ['path', 'rectangle', 'circle', 'ellipse', 'line', 'polyline', 'polygon']
    .map(elementType => svg.querySelectorAll(elementType))
    .filter(nodeList => nodeList.length !== 0)
    .forEach(nodeList => {
      nodeList.forEach((element: any) => {
        if (!element.getAttribute('fill') && !element.getAttribute('class')) {
          element.setAttribute('fill', '#000000');
        }
      });
    });
};

export const getObject = (shapeProps: ShapeProps): Promise<unknown> | undefined => {
  let obj;
  switch (shapeProps.subType) {
    case NODE_TYPE.IMAGE:
      obj = getImage(shapeProps as ImageProps);
      break;
    case NODE_TYPE.TEXT:
    case NODE_TYPE.TEXT_IMAGE:
      obj = getTextbox(shapeProps as TextProps);
      break;
    case NODE_TYPE.FOLDER:
      obj = Promise.all((shapeProps as FolderProps).children?.map(child => getObject(child))).then(shapes => {
        const group = new fabric.Group(shapes as fabric.Object[]);
        (group as FabricGroupExtended).set({ id: v4(), layerType: LAYER_TYPE.SHAPE });
        return group;
      });
      break;
    case NODE_TYPE.CUSTOM_SHAPE:
      obj = getCustomShape(shapeProps);
    default:
      //obj = getRectangle(shapeProps);
      break;
  }

  return obj;
};

export const getGroup = async (shapeProps: ShapeProps) => {
  const shapes = await Promise.all((shapeProps as FolderProps).children?.map(child => getObject(child)));
  return new Promise(res => {
    res(new fabric.Group(shapes as fabric.Object[], { top: shapeProps.y, left: shapeProps.x }));
  });
};

export const enlivenObjectsCustom = (children: any, callback: Function, option: string) => {
  Promise.all(children.map((child: any) => new Promise((res) => {
    if (child.isSvg && child.objects && child.objects.length) {
      // backend compatibility for old projects svg loading as image
      const { left, top, exportedAsset, width, height, scaleX, scaleY, angle,
        opacity, name, layerType, flipX, flipY, isBackground } = child;
      const extractedObj = {
        x: left, y: top, exportedAsset, width, height, scaleX, scaleY, rotation: angle,
        opacity, name, layerType, flipX, flipY, isBackground, selectable: true,
      };
      loadSvg(extractedObj, res);
    } else {
      fabric.util.enlivenObjects([child], (objs: any) => {
        res(objs[0])
      }, option)
    }
  }))).then((aliveObjs: any) => {
    callback(aliveObjs);
  });
}