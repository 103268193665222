import { getFormData } from './../../utilities/common-function';
import axios from 'axios';
import { put, takeLatest, all,call } from 'redux-saga/effects';
import { Login, LOGIN, FetchAccessToken, FETCH_ACCESS_TOKEN, UpdateProfile, UPDATE_PROFILE, AUTHORIZE, Authorize, SEND_RESET_LINK, SendResetLink } from "../types";
import HttpService from '../../HttpService/HttpService';
import { appAPI,reportingLoginAPI } from '../../MappedAPI/MappedAPI';
import { LOCUS_URI,APP_ID,REPORT_URI } from '../../utilities/paths';
import { openToast } from '../../Toasts';
import { store } from '../store';

function* login(action: Login) {
  let { payload,callback } = action;
  try{
    let params = `/${APP_ID}/login`;
    let response = yield call(HttpService.post,LOCUS_URI,`${appAPI}${params}`,undefined,payload)
    
    if (!response.data.error) {
      callback && callback(response.data,false);
      openToast('success',response?.data?.data?.message || "Login Successfull!")
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
    openToast('error',error?.response?.data?.message || "Something Went Wrong!")
  }
}

function* fetchAccessToken(action: FetchAccessToken) {
  let { callback } = action;
  let TOKEN:any = store.getState().authentication.token; 
  try{
    let params = `/?app_id=${APP_ID}&token=${TOKEN}`;
    let response = yield call(HttpService.post,REPORT_URI,`${reportingLoginAPI}${params}`,undefined,{})
    
    if (!response.data.error) {
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
    // openToast('error',error?.response?.data?.message || "Something Went Wrong!")
  }
}

function* updateProfile(action: UpdateProfile) {
  let { payload, callback } = action;
  let TOKEN:any = store.getState().authentication.token;
  const form_data = getFormData(payload)

  try {
    let params = `/${APP_ID}/user/update/me?secret_token=${TOKEN}`;
    let response = yield call(HttpService.patch,LOCUS_URI,`${appAPI}${params}`,undefined,form_data);
    if(!response.data.error) {
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch(error) {
    callback && callback(error.response, true);
    openToast('error', error?.response?.data?.message || "Something Went Wrong!")    
  }
}

function* authorize(action: Authorize) {
  let { payload: { token }, callback } = action;

  try {
    let params = `/${APP_ID}/user/authorize/me?secret_token=${token}`;
    let response = yield call(HttpService.get,LOCUS_URI,`${appAPI}${params}`,undefined);
    if(!response.data.error) {
      callback && callback(response.data,false);
    } else {
      callback && callback(response, true);
    }
  } catch(error) {
    callback && callback(error.response, true);
    openToast('error', error?.response?.data?.message || "Something Went Wrong!")    
  }
}

function* sendResetLinkSaga(action: SendResetLink) {
  const { payload: { email }, callback } = action;

  try {
    const params = `/${APP_ID}/reset-password?email=${email}`;
    const response = yield call(HttpService.get, LOCUS_URI, `${appAPI}${params}`, undefined);
    console.log({response})
    if(!response.data.error) {
      callback(response.data, false);
    } else {
      callback(response, true);
    }
  } catch(error) {
    callback(error.response, true);
    openToast('error', error?.response?.data?.message || "Password reset link failed.")    
  }
}

export function* AuthenticationWatcher() {
  yield takeLatest(LOGIN, login)
  yield takeLatest(FETCH_ACCESS_TOKEN, fetchAccessToken)
  yield takeLatest(UPDATE_PROFILE, updateProfile)
  yield takeLatest(AUTHORIZE, authorize)
  yield takeLatest(SEND_RESET_LINK, sendResetLinkSaga)
}