import { FC, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Folder, FolderState } from "../../../store/types/projectFolders";
import iconSearchFolder from "../../../assets/svg/icon-search-folder.svg";
import React from "react";
import "./index.scss";
import { Tooltip } from "pyxis-ui-kit";
interface FolderInterface {
  folder: Folder;
  index: number;
  type: string;
  setDrawerVisible: Function;
  setCurrentProject: Function;
  folders: Folder[];
  changeActiveFolderInRouteForward: Function;
  canCreate: boolean;
  setPublicLinkDrawerProps?: Function;
}
export const SearchFolders: FC<FolderInterface> = ({
  folder,
  setDrawerVisible,
  canCreate,
  index,
  type,
  setCurrentProject,
  folders,
  changeActiveFolderInRouteForward,
  setPublicLinkDrawerProps,
}) => {
  const state = useSelector((state: { folders: FolderState }) => state.folders);
  const onDoubleClick = useCallback((folder: any) => {
    changeActiveFolderInRouteForward(folder.id);
  }, []);
  return (
    <div
      key={folder.id}
      // onClick={(e) => onSingleClick(folder.id, index, e.shiftKey)}
      onClick={() => {
        onDoubleClick(folder);
      }}
      className='adv-search-folder'
    >
      <img src={iconSearchFolder} className='folder-icon-search' alt='' />
      {folder.name.length <= 15 ? (
        <span className='folder-search-name'>{folder.name}</span>
      ) : (
        <Tooltip
          title={folder.name}
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          content={
            <span className='folder-search-name'>
              {folder.name.slice(0, 16) + "..."}
            </span>
          }
        ></Tooltip>
      )}
    </div>
  );
};
