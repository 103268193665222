import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  createHyperProject,
  fetchHyperProjects,
  fetchProjects,
  getStatusSummary,
  getUsersData,
  setFoldersPageData,
  setProjectLoader,
  setProjects,
  setProjectsForReadyCheck,
} from "../../../store/actions";
import {
  Banner,
  FolderState,
  HyperProjectFoldersState,
  RolesState,
} from "../../../store/types";
import { openToast } from "../../../Toasts";
import {
  detectQueryString,
  drawerRenameTitle,
} from "../../../utilities/common-function";
import { IMAGE_BASE_URL } from "../../../utilities/paths";
import ProjectCard from "../../DashboardSection/ProjectCard";
import EmptyPlaceHolder from "../../shared/EmptyPlaceHolder";
import Masonry from "../../shared/Masonry";
import NoResultsFound from "../../../assets/img/noresultsfound.png";
import { ApprovalAlert, EditAlert } from "../../shared";

const ProjectListing: React.FC<{
  type: string;
  filter: any;
  setPublicLinkDrawerProps: Function;
}> = ({ type, filter, setPublicLinkDrawerProps }) => {
  const state = useSelector((state: { folders: FolderState }) => state.folders);
  const { projectsForReadyCheck } = useSelector(
    (state: { hyperProject: HyperProjectFoldersState }) => state.hyperProject
  );
  const dispatch = useDispatch();
  const scrollTopRef = useRef(null);
  const { pageData, projectLoading, banners } = state;
  const [drawerVisible, setDrawerVisible] = useState("");
  const [currentProject, setCurrentProject] = useState<any>([]);
  let renameTitle = drawerRenameTitle(drawerVisible, type);

  const fetchAllProjects = (page: number) => {
    if (!projectLoading) dispatch(setProjectLoader(true));
    dispatch(
      fetchProjects({ ...filter, page }, (res: any, error: boolean) => {
        if (!error) {
          dispatch(
            setFoldersPageData({
              count: res.count,
              current_page: res.current_page,
              page_size: res.page_size,
              pages: res.pages,
            })
          );
          res = res.data;
          let ids = res.reduce(
            (acc: number[], val: any) => [...acc, val.id],
            []
          );
          let userIds = res.reduce((acc: any, value: any) => {
            if (!acc.includes(value.created_by)) {
              acc.push(value.created_by);
            }
            return acc;
          }, []);
          if (userIds.length > 0) {
            dispatch(setProjectLoader(true));
            dispatch(
              getUsersData(
                { userIds: userIds },
                (userres: any, usererr: boolean) => {
                  if (!usererr) {
                    let { users } = userres.data;
                    res.map((project: any, index: number) => {
                      project.created_by =
                        users.find(
                          (user: any) => user._id === project.created_by
                        ) || "";
                    });
                  }
                  dispatch(setProjectLoader(false));
                }
              )
            );
          }
          dispatch(
            getStatusSummary(
              { ids, entity_type: type },
              (summaryRes: any, summaryErr: boolean) => {
                dispatch(setProjectLoader(false));
                if (!summaryErr) {
                  let {
                    data: { results },
                  } = summaryRes;
                  results.forEach((d: any) => {
                    let found: any = res.findIndex(
                      (obj: any) => obj.id.toString() === d.entity_id
                    );
                    if (found !== -1) {
                      res[found]["status"] = d.status;
                      res[found]["entity_id"] = d.entity_id;
                    }
                  });
                }
                if (page > 1) {
                  dispatch(setProjects([...banners, ...res]));
                } else {
                  dispatch(setProjects(res));
                }
              }
            )
          );
        } else {
          dispatch(setProjectLoader(false));
        }
      })
    );
  };

  const fetchAllHyperProjects = (page: number) => {
    if (!projectLoading) dispatch(setProjectLoader(true));
    dispatch(
      fetchHyperProjects(
        { ...filter, page, page_size: 20 },
        (res: any, err: boolean) => {
          if (!err) {
            let ids = res.reduce(
              (acc: number[], val: any) => [...acc, val.id],
              []
            );
            let userIds = res.reduce(
              (acc: number[], val: any) => [...acc, val.created_by],
              []
            );
            if (userIds.length > 0) {
              dispatch(
                getUsersData(
                  { userIds: userIds },
                  (userres: any, usererr: boolean) => {
                    if (!usererr) {
                      let { users } = userres.data;
                      res.map((project: any, index: number) => {
                        project.created_by =
                          users.find(
                            (user: any) => user._id === project.created_by
                          ) || "";
                      });
                    }
                  }
                )
              );
            }
            dispatch(
              getStatusSummary(
                { ids, entity_type: type },
                (summaryRes: any, summaryErr: boolean) => {
                  dispatch(setProjectLoader(false));
                  if (!summaryErr) {
                    let {
                      data: { results },
                    } = summaryRes;
                    results.forEach((d: any) => {
                      let found: any = res.findIndex(
                        (obj: any) => obj.id.toString() === d.entity_id
                      );
                      if (found !== -1) {
                        res[found]["status"] = d.status;
                        res[found]["entity_id"] = d.entity_id;
                      }
                    });
                  }
                  if (page > 1) {
                    dispatch(setProjects([...banners, ...res]));
                  } else {
                    dispatch(setProjects(res));
                  }
                }
              )
            );
          } else {
            dispatch(setProjectLoader(false));
          }
        }
      )
    );
  };

  const createHProject = (data: any, setLoader?: Function) => {
    setLoader && setLoader(true);
    dispatch(
      createHyperProject(
        { project_id: data.id, title: data.title, description: "" },
        (res: any, err: boolean) => {
          setLoader && setLoader(false);
          let { data } = res;
          if (!err) {
            let hyperProject = {
              id: data.id,
              parent: 0,
              title: data.title,
              type: data.type,
              can_manage: data.can_manage || true,
              created_by: data.created_by,
              created_at: data.created_at,
              render_status: data.render_status,
              ready_status: data.ready_status,
              ready_failure_reason: data.ready_failure_reason,
              ready_progress: data.ready_progress,
              rendered_file:
                IMAGE_BASE_URL +
                data.rendered_file +
                (detectQueryString(data.rendered_file) ? "&q=40" : "?q=40"),
            };
            if (type === "hpproject") {
              dispatch(setProjects([hyperProject, ...banners]));
            } else {
              res.data = {
                ...res.data,
                rendered_file: IMAGE_BASE_URL + data.rendered_file,
              };
              dispatch(
                setProjectsForReadyCheck([...projectsForReadyCheck, res.data])
              );
            }
            openToast("success", "Creative automation project created!");
          } else {
            openToast("error", "Something went wrong");
          }
        }
      )
    );
  };

  useEffect(() => {
    if (type === "project") {
      fetchAllProjects(1);
    } else if (type === "hpproject") {
      fetchAllHyperProjects(1);
    }
    return () => {
      dispatch(setProjects([]));
      dispatch(setFoldersPageData(null));
    };
  }, [filter]);

  const scrollToTop = () => {
    let node: any = scrollTopRef?.current;
    if (node) {
      node.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className='projects-listing'>
      <div ref={scrollTopRef} style={{ display: "none" }}></div>
      {(projectLoading || banners.length > 0) && (
        <InfiniteScroll
          dataLength={banners.length}
          next={() => {
            if (pageData && type === "project") {
              fetchAllProjects(pageData.current_page + 1);
            }
            if (pageData && type === "hpproject") {
              fetchAllHyperProjects(pageData.current_page + 1);
            }
          }}
          hasMore={(pageData?.current_page || 0) < (pageData?.pages || 0)}
          loader={null}
          className='columns'
          scrollableTarget='creatives-advance-search'
          style={{ overflow: "none" }}
        >
          <Masonry
            className='masonry'
            columnClassName='column'
            loader={
              projectLoading ? (
                <div className='project-card'>
                  <div className='card'>
                    <div className='card-body pulsate'></div>
                  </div>
                </div>
              ) : null
            }
          >
            {banners.map((banner: Banner, index: number) => (
              <ProjectCard
                allowSelection={false}
                data={banner}
                componentCaller='project'
                type='project'
                key={banner.id}
                createHyperProject={createHProject}
                scrollToTop={scrollToTop}
                projectIndex={index}
                setPublicLinkDrawerProps={setPublicLinkDrawerProps}
                setCurrentProject={setCurrentProject}
                drawerVisible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
              />
            ))}
          </Masonry>
        </InfiniteScroll>
      )}
      {(drawerVisible === "rename" ||
        drawerVisible === "addToTemplate" ||
        drawerVisible === "creativeAutomation") && (
        <EditAlert
          title={
            (drawerVisible === "rename" ? "Rename " : "") +
            renameTitle +
            (drawerVisible !== "rename" ? " name" : "")
          }
          subTitle={
            "Enter a new " +
            renameTitle +
            " name" +
            (drawerVisible === "rename" ? " for easy discovery" : "")
          }
          label={renameTitle + " name"}
          isRenameDrawerVisible={
            drawerVisible === "rename" ||
            drawerVisible === "addToTemplate" ||
            drawerVisible === "creativeAutomation"
          }
          successTitle={
            "Great! " +
            renameTitle +
            (drawerVisible === "rename" ? " renamed" : " added") +
            " successfully"
          }
          successSubTitle=''
          projectType={type}
          currentProject={currentProject}
          setDrawerVisible={setDrawerVisible}
          drawerOpenFor={drawerVisible}
          createHyperProject={createHyperProject}
        />
      )}
      {drawerVisible === "approval" && (
        <ApprovalAlert
          projectId={currentProject.id}
          isApproval={drawerVisible === "approval"}
          projectType={type}
          banners={banners}
          componentCaller='project'
          setDrawerVisible={setDrawerVisible}
        />
      )}
      {!projectLoading && banners.length === 0 && (
        // <EmptyPlaceHolder
        //   icon={<img src={NoResultsFound} />}
        //   text='Oops! Nothing found! Please, try another search request'
        //   isSubmitBtn={false}
        // />
        <div className='no-data-placement'>No search results under Files</div>
      )}
    </div>
  );
};

export default ProjectListing;
