const aiTemplates = {
    
    "1": {
        "error": false,
        "data": {
            "id": 44560,
            "title": "My Project",
            "description": "",
            "type": "image",
            "template": null,
            "mytemplate": null,
            "project_json": {
                "default": [],
                "original": {
                    "dpi": 96,
                    "key": "original",
                    "children": [
                        {
                            "id": "aae8aab9-6b31-4935-bb73-6e41eb2781fc",
                            "src": "https://aiquire-creative-stg-backend.s3.ap-south-1.amazonaws.com/text-to-image/cocreate-1703155849-a1e7a487-e248-4bfd-a7e3-8109556c1087.png",
                            "top": 318.66,
                            "fill": "rgb(0,0,0)",
                            "left": -89.24,
                            "name": "BG Image",
                            "type": "image",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            // "width": 1024,
                            // "height": 573,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "filters": [],
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Image",
                            "paintFirst": "fill",
                            "crossOrigin": "anonymous",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "b1f76e07-aeee-415c-9ca9-0ce63ad8eb58",
                            "src": "https://d2ooeus47rmo0m.cloudfront.net/aiTemplates/1/33a41cb8-aa0a-4705-b3da-62dd31485a5b.png",
                            "top": -19,
                            "fill": "rgb(0,0,0)",
                            "left": 0,
                            "name": "Shape 1",
                            "type": "image",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 1080,
                            "height": 347,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "filters": [],
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Image",
                            "paintFirst": "fill",
                            "crossOrigin": "anonymous",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "55110df0-09a7-42be-b7fe-00096c857f80",
                            "top": 1044,
                            "fill": "#ffffff",
                            "left": 32,
                            "name": "DYN_T&C",
                            "text": "*T&C Apply",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 102,
                            "height": 20.34,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 18,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "layerType": "Text",
                            "textAlign": "left",
                            "underline": false,
                            "fontFamily": "HelveticaNeueLTStd-45Light",
                            "fontWeight": "normal",
                            "lineHeight": 1,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "isCustomFont": true,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "splitByGrapheme": true,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "8c5735c8-4dba-49cd-9c25-7a9890ca7b03",
                            "rx": 0,
                            "ry": 0,
                            "top": 924,
                            "fill": "#ffffff",
                            "left": -77,
                            "name": "DYN_OFFERBOX",
                            "type": "rect",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 495,
                            "height": 66,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "filters": [],
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Shape",
                            "paintFirst": "fill",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "2b598b7c-b9a2-4287-9837-0cf4817d8fcb",
                            "top": 938,
                            "fill": "#104988",
                            "left": 25,
                            "name": "DYN_PUSH",
                            "text": "Limited Time Offer",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 369.6,
                            "height": 40.53,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 35.87,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "layerType": "Text",
                            "textAlign": "center",
                            "underline": false,
                            "fontFamily": "HelveticaNeue-Bold",
                            "fontWeight": "normal",
                            "lineHeight": 1,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "isCustomFont": true,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "splitByGrapheme": true,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "d7d90918-8592-4f3b-9fbf-cf80976bc224",
                            "top": 233,
                            "fill": "#104988",
                            "left": 73,
                            "name": "DYN_HASHTAG",
                            "text": "#XpressBusinessLoan",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 560.4,
                            "height": 44.07,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 39,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "layerType": "Text",
                            "textAlign": "left",
                            "underline": false,
                            "fontFamily": "HelveticaNeueLTStd-45Light",
                            "fontWeight": "normal",
                            "lineHeight": 1,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "isCustomFont": true,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "splitByGrapheme": true,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "438f5b95-70c7-4d41-b235-c1bea4b7c082",
                            "top": 85.99,
                            "fill": "#104988",
                            "left": 73,
                            "name": "DYN_HEADER",
                            "text": "Fuel your entrepreneurial dreams",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 697.73,
                            "height": 133.11,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 62,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "layerType": "Text",
                            "textAlign": "left",
                            "underline": false,
                            "fontFamily": "HelveticaNeue-Bold",
                            "fontWeight": "normal",
                            "lineHeight": 0.9,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "isCustomFont": true,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "splitByGrapheme": false,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "75e5e2c7-13c9-44f2-a6ba-e55bdd84ee8c",
                            "rx": 0,
                            "ry": 0,
                            "top": 289,
                            "fill": "rgb(227,44,44)",
                            "left": 73,
                            "name": "DYN_CTABOX",
                            "type": "rect",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 267,
                            "height": 77,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "filters": [],
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Shape",
                            "paintFirst": "fill",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "30e2a90a-d253-43bb-ac97-bbd2917ad1dd",
                            "top": 301,
                            "fill": "#ffffff",
                            "left": 100,
                            "name": "DYN_CTA_TEXT",
                            "text": "Know more",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 230.4,
                            "height": 53.26,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 47.13,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "layerType": "Text",
                            "textAlign": "left",
                            "underline": false,
                            "fontFamily": "HelveticaNeueLTStd-67MediumCondensed",
                            "fontWeight": "normal",
                            "lineHeight": 1,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "isCustomFont": true,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            // "splitByGrapheme": true,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "aa10de4f-153d-4003-90b5-a8eb0ed1e5a5",
                            "src": "https://d2ooeus47rmo0m.cloudfront.net/aiTemplates/1/e4c4872f-0a26-4dcc-8e4d-6b84cf971669.png",
                            "top": 23,
                            "fill": "rgb(0,0,0)",
                            "left": 780,
                            "name": "LOGO",
                            "type": "image",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 277,
                            "height": 51,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "filters": [],
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Image",
                            "paintFirst": "fill",
                            "crossOrigin": "anonymous",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        }
                    ],
                    "backgroundColor": "#ffffff"
                },

            },
            "sizes": [
                {
                    "name": "custom1",
                    "type": "custom",
                    "width": 1080,
                    "height": 1080
                }
            ],
            "renderedimages": [
                {
                    "id": 93162,
                    "size_name": "custom1",
                    "size_type": "custom",
                    "width": 1080.0,
                    "height": 1080.0,
                    "rendered_image": "project-rendered-files/4c323f30-9ff0-11ee-a532-42d1fa2edc9a",
                    "render_status": "COMPLETED",
                    "is_changed_from_last_render": false,
                    "failed_reason": "",
                    "psdUrl": null
                }
            ],
            "rendered_count": 1,
            "total_count": 1,
            "rendering_count": 0,
            "failed_count": 0,
            "render_failure_reason": "",
            "rendered_file": "project-rendered-files/4c323f30-9ff0-11ee-a532-42d1fa2edc9a",
            "created_by": "5fa91cef803fe80007e484cb",
            "modified_at": "2023-12-21T16:31:37.272529+05:30",
            "render_status": "COMPLETED",
            "created_at": "2023-12-21T16:21:30.194774+05:30",
            "tags": [
                "crimson",
                "lightsalmon",
                "slategray",
                "lightsteelblue",
                "#XpressBusinessLoan",
                "*T&C Apply",
                "darkslategray",
                "darkslateblue",
                "lightgray",
                "darkgray",
                "lavender",
                "white",
                "Limited Time Offer",
                "darkolivegreen",
                "rosybrown",
                "black",
                "steelblue",
                "indianred",
                "Know more",
                "cadetblue",
                "dimgray",
                "Fuel your entrepreneurial dreams"
            ],
            "auto_tags": [],
            "file_type": "psd",
            "psdUrl": "https://aiquire-creative-stg-backend.s3.ap-south-1.amazonaws.com/aidesigner/60069086999e4000099dcfaa/44560/test.png",
            "primary_text": "",
            "headline": "",
            "can_manage": true
        }
    },
    "2": {
        "error": false,
        "data": {
            "id": 44559,
            "title": "My Project",
            "description": "",   
            "type": "image",
            "template": null,
            "mytemplate": null,
            "project_json": {
                "default": [],
                "original": {
                    "dpi": 96,
                    "key": "original",
                    "children": [
                        {
                            "id": "1851d34e-2119-4fc5-a7eb-dc7b1e563c2e",
                            "src": "https://d2ooeus47rmo0m.cloudfront.net/aiTemplates/1/8b4c8962-711d-491f-8d3b-5e5681a9dfea.png",
                            "top": 0,
                            "fill": "rgb(0,0,0)",
                            "left": 0,
                            "name": "Shape",
                            "type": "image",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 1080,
                            "height": 1080,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "filters": [],
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Image",
                            "paintFirst": "fill",
                            "crossOrigin": "anonymous",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "5e9132f4-9355-44f2-ac27-3ab53d2bff66",
                            "src": "https://aiquire-creative-stg-backend.s3.ap-south-1.amazonaws.com/text-to-image/cocreate-1703155817-eb84e14d-d9c4-40df-9ca2-e41176890da6.png",
                            "top": 323.35,
                            "fill": "rgb(0,0,0)",
                            "left": -54.08,
                            "name": "BG Image",
                            "type": "image",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            // "width": 1024,
                            // "height": 573,
                            "scaleX": 1.2,
                            "scaleY": 1.2,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "filters": [],
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Image",
                            "paintFirst": "fill",
                            "crossOrigin": "anonymous",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "f36c5383-8b1f-44db-ae88-e442f98cb082",
                            "src": "https://d2ooeus47rmo0m.cloudfront.net/aiTemplates/1/b54fe09d-616a-40f1-ae02-4ccd767d10d7.png",
                            "top": 1014,
                            "fill": "rgb(0,0,0)",
                            "left": -103,
                            "name": "DYN_OFFERBOX",
                            "type": "image",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 1287,
                            "height": 66,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "filters": [],
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Image",
                            "paintFirst": "fill",
                            "crossOrigin": "anonymous",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "47ecfca1-ccbb-4ecd-b783-dc065bd643da",
                            "rx": 0,
                            "ry": 0,
                            "top": 296,
                            "fill": "rgb(227,44,44)",
                            "left": 58,
                            "name": "DYN_CTABOX",
                            "type": "rect",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 420,
                            "height": 67,
                            "scaleX": 0.92,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "filters": [],
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Shape",
                            "paintFirst": "fill",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "98727abf-a188-42a4-ad40-34778477fc0a",
                            "top": 302.5,
                            "fill": "#ffffff",
                            "left": 82,
                            "name": "DYN_CTA_TEXT",
                            "text": "Check loan amount",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 350.12,
                            "height": 50.85,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 45,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "layerType": "Text",
                            "textAlign": "left",
                            "underline": false,
                            "fontFamily": "HelveticaNeueLTStd-67MediumCondensed",
                            "fontWeight": "normal",
                            "lineHeight": 1,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "isCustomFont": true,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            // "splitByGrapheme": true,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "d27f99cd-ea10-4d90-b4b3-ace75f3372ed",
                            "top": 232,
                            "fill": "#ffffff",
                            "left": 59,
                            "name": "DYN_HASHTAG",
                            "text": "#InstantEducationLoan",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 627.6,
                            "height": 44.07,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 39,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "layerType": "Text",
                            "textAlign": "left",
                            "underline": false,
                            "fontFamily": "HelveticaNeueLTStd-45Light",
                            "fontWeight": "normal",
                            "lineHeight": 1,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "isCustomFont": true,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "splitByGrapheme": true,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "5871b091-8ccf-406c-b95f-9a73c8c1a50a",
                            "top": 1025,
                            "fill": "#152f5f",
                            "left": 29,
                            "name": "DYN_PUSH",
                            "text": "Limited Time Offer",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 311.58,
                            "height": 39.55,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 35,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "layerType": "Text",
                            "textAlign": "left",
                            "underline": false,
                            "fontFamily": "HelveticaNeue-Bold",
                            "fontWeight": "normal",
                            "lineHeight": 1,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "isCustomFont": true,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "splitByGrapheme": true,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "6680f748-7213-4c32-b783-debe9f3706ed",
                            "top": 1035.34,
                            "fill": "#244884",
                            "left": 938,
                            "name": "DYN_T&C",
                            "text": "*T&C Apply",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 105.6,
                            "height": 20.34,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 18,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "layerType": "Text",
                            "textAlign": "left",
                            "underline": false,
                            "fontFamily": "HelveticaNeueLTStd-45Light",
                            "fontWeight": "normal",
                            "lineHeight": 1,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "isCustomFont": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "splitByGrapheme": true,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "37065541-7217-4953-8a23-0699a6ff107a",
                            "src": "https://d2ooeus47rmo0m.cloudfront.net/aiTemplates/1/605707e6-044f-4cc2-b442-fd82d431f874.png",
                            "top": 38,
                            "fill": "rgb(0,0,0)",
                            "left": 767,
                            "name": "LOGO",
                            "type": "image",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 277,
                            "height": 50,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "filters": [],
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Image",
                            "paintFirst": "fill",
                            "crossOrigin": "anonymous",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "top": 75.05,
                            "fill": "#ffffff",
                            "left": 59,
                            "name": "DYN_HEADER",
                            "text": "Achieve Your Dream University Goals",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 651.48,
                            "height": 149.43,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 66.11984266217749,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "textAlign": "left",
                            "underline": false,
                            "fontFamily": "HelveticaNeue-Bold",
                            "fontWeight": "normal",
                            "lineHeight": 1,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "splitByGrapheme": false,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        }
                    ],
                    "backgroundColor": "#ffffff"
                },

            },
            "sizes": [
                {
                    "name": "custom1",
                    "type": "custom",
                    "width": 1080,
                    "height": 1080
                }
            ],
            "renderedimages": [
                {
                    "id": 93161,
                    "size_name": "custom1",
                    "size_type": "custom",
                    "width": 1080.0,
                    "height": 1080.0,
                    "rendered_image": "project-rendered-files/d18825aa-9ff0-11ee-95c1-72e77949777c",
                    "render_status": "COMPLETED",
                    "is_changed_from_last_render": false,
                    "failed_reason": "",
                    "psdUrl": null
                }
            ],
            "rendered_count": 1,
            "total_count": 1,
            "rendering_count": 0,
            "failed_count": 0,
            "render_failure_reason": "",
            "rendered_file": "project-rendered-files/d18825aa-9ff0-11ee-95c1-72e77949777c",
            "created_by": "5fa91cef803fe80007e484cb",
            "modified_at": "2023-12-21T16:35:32.884733+05:30",
            "render_status": "COMPLETED",
            "created_at": "2023-12-21T16:20:59.261952+05:30",
            "tags": [
                "crimson",
                "slategray",
                "lightsteelblue",
                "gray",
                "lightcoral",
                "*T&C Apply",
                "darkslategray",
                "darkslateblue",
                "sienna",
                "lightgray",
                "lightslategray",
                "darkgray",
                "lavender",
                "white",
                "Check loan amount",
                "lightpink",
                "Achieve Your Dream University Goals",
                "darksalmon",
                "Limited Time Offer",
                "darkolivegreen",
                "rosybrown",
                "black",
                "#InstantEducationLoan",
                "steelblue",
                "dimgray"
            ],
            "auto_tags": [],
            "file_type": "psd",
            "psdUrl": "https://aiquire-creative-stg-backend.s3.ap-south-1.amazonaws.com/aidesigner/60069086999e4000099dcfaa/44559/test.png",
            "primary_text": "",
            "headline": "",
            "can_manage": true
        }
    },
    "3": {
        "error": false,
        "data": {
            "id": 44561,
            "title": "My Project",
            "description": "",
            "type": "image",
            "template": null,
            "mytemplate": null,
            "project_json": {
                "default": [],
                "original": {
                    "dpi": 96,
                    "key": "original",
                    "children": [
                        {
                            //giving 404
                            "src": "https://d2ooeus47rmo0m.cloudfront.net/aiTemplates/1/blob_5jRSCHr",
                            "top": 0,
                            "fill": "rgb(0,0,0)",
                            "left": -172.8,
                            "name": "BG Image",
                            "type": "image",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            // "width": 1024,
                            // "height": 573,
                            "scaleX": 1.3,
                            "scaleY": 1.3,
                            "shadow": null,
                            "stroke": null,
                            "filters": [],
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Image",
                            "paintFirst": "fill",
                            "crossOrigin": "anonymous",
                            "strokeWidth": 0,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "990906eb-2291-4fbe-96d6-885670fa6287",
                            "src": "https://d2ooeus47rmo0m.cloudfront.net/aiTemplates/1/e4c4872f-0a26-4dcc-8e4d-6b84cf971669.png",
                            "top": 40,
                            "fill": "rgb(0,0,0)",
                            "left": 41,
                            "name": "DYN_LOGO",
                            "type": "image",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 304,
                            "height": 55,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "filters": [],
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Image",
                            "paintFirst": "fill",
                            "crossOrigin": "anonymous",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "4eb9ffcf-1faf-46d8-a992-66e30f9f0fd9",
                            // "src": "https://d2ooeus47rmo0m.cloudfront.net/aiTemplates/1/11c0f354-e138-4afe-bf9b-ae428ed722f4.png",
                            "top": -6.49,
                            "fill": "#002857",
                            "left": 533.35,
                            "name": "DYN_SHAPE",
                            "type": "rect",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 593,
                            "height": 648,
                            "scaleX": 0.87,
                            "scaleY": 0.77,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "filters": [],
                            "opacity": 0.9,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Shape",
                            "paintFirst": "fill",
                            "crossOrigin": "anonymous",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "c73bf628-8f8c-4049-a1f9-47e9394c15fb",
                            "top": 360.89,
                            "fill": "#ffffff",
                            "left": 704.95,
                            "name": "DYN_HASHTAG",
                            "text": "#XpressCarLoan",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 296.36,
                            "height": 44.07,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 39,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "layerType": "Text",
                            "textAlign": "right",
                            "underline": false,
                            "fontFamily": "HelveticaNeueLTStd-45Light",
                            "fontWeight": "normal",
                            "lineHeight": 1,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "isCustomFont": true,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            // "splitByGrapheme": true,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "23e68718-14bf-4896-8671-75e2844f0176",
                            "rx": 11.187492634638803,
                            "ry": 12.106750993967815,
                            "top": 452.35,
                            "fill": "rgb(33, 136, 8)",
                            "left": 604.95,
                            "name": "DYN_CTABOX",
                            "type": "rect",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 445,
                            "height": 96,
                            "scaleX": 0.89,
                            "scaleY": 0.83,
                            "shadow": null,
                            "stroke": "#000000",
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Shape",
                            "paintFirst": "fill",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "adbc8a51-25d5-408a-95a1-39aa9c875c5d",
                            "top": 467.09,
                            "fill": "#ffffff",
                            "left": 635.05,
                            "name": "DYN_CTA_TEXT",
                            "text": "Check loan amount",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 355.08,
                            "height": 50.85,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 45,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "layerType": "Text",
                            "textAlign": "left",
                            "underline": false,
                            "fontFamily": "HelveticaNeueLTStd-67MediumCondensed",
                            "fontWeight": "normal",
                            "lineHeight": 1,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "isCustomFont": true,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            // "splitByGrapheme": true,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "68cd1398-f192-42c0-ada1-e9356ab2ef5a",
                            "src": "https://d2ooeus47rmo0m.cloudfront.net/aiTemplates/1/76303761-224b-4030-aa1c-b5f8dd638039.png",
                            "top": 1009,
                            "fill": "rgb(0,0,0)",
                            "left": -91,
                            "name": "DYN_SHAPE 2",
                            "type": "image",
                            "angle": 0,
                            "cropX": 0,
                            "cropY": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 1196,
                            "height": 125,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "filters": [],
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "layerType": "Image",
                            "paintFirst": "fill",
                            "crossOrigin": "anonymous",
                            "strokeWidth": 0,
                            "isBackground": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "35b47175-1d51-41cb-bf95-d3da5b702fa4",
                            "top": 1041.21,
                            "fill": "#000000",
                            "left": 943.26,
                            "name": "DYN_T&C",
                            "text": "*T&C Apply",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 105.6,
                            "height": 20.34,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 18,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "layerType": "Text",
                            "textAlign": "left",
                            "underline": false,
                            "fontFamily": "HelveticaNeueLTStd-45Light",
                            "fontWeight": "normal",
                            "lineHeight": 1,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "isCustomFont": false,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            // "splitByGrapheme": true,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "id": "3cc19230-695f-4b9a-a7ad-9a28db237f49",
                            "top": 1032.38,
                            "fill": "#092c57",
                            "left": 24,
                            "name": "DYN_PUSH",
                            "text": "Today",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 358.8,
                            "height": 38.42,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 34,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "layerType": "Text",
                            "textAlign": "left",
                            "underline": false,
                            "fontFamily": "HelveticaNeue-Bold",
                            "fontWeight": "normal",
                            "lineHeight": 1,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "isCustomFont": true,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            // "splitByGrapheme": true,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        },
                        {
                            "top": 108.99,
                            "fill": "#ffffff",
                            "left": 588.74,
                            "name": "DYN_HEADER",
                            "text": "Unlock your dream car today",
                            "type": "textbox",
                            "angle": 0,
                            "flipX": false,
                            "flipY": false,
                            "skewX": 0,
                            "skewY": 0,
                            "width": 412.21,
                            "height": 230.97,
                            "scaleX": 1,
                            "scaleY": 1,
                            "shadow": null,
                            "stroke": "#ffffff",
                            "styles": {},
                            "opacity": 1,
                            "originX": "left",
                            "originY": "top",
                            "version": "4.3.1",
                            "visible": true,
                            "fillRule": "nonzero",
                            "fontSize": 73,
                            "minWidth": 20,
                            "overline": false,
                            "fontStyle": "normal",
                            "textAlign": "right",
                            "underline": false,
                            "fontFamily": "HelveticaNeue-Bold",
                            "fontWeight": "normal",
                            "lineHeight": 0.9,
                            "paintFirst": "fill",
                            "charSpacing": 0,
                            "linethrough": false,
                            "strokeWidth": 0,
                            "strokeLineCap": "butt",
                            "strokeUniform": false,
                            "strokeLineJoin": "miter",
                            "backgroundColor": "",
                            // "splitByGrapheme": false,
                            "strokeDashArray": null,
                            "strokeDashOffset": 0,
                            "strokeMiterLimit": 4,
                            "textBackgroundColor": "",
                            "globalCompositeOperation": "source-over"
                        }
                    ],
                    "backgroundColor": "#ffffff"
                },

            },
            "sizes": [
                {
                    "name": "custom1",
                    "type": "custom",
                    "width": 1080,
                    "height": 1080
                }
            ],
            "renderedimages": [
                {
                    "id": 93163,
                    "size_name": "custom1",
                    "size_type": "custom",
                    "width": 1080.0,
                    "height": 1080.0,
                    "rendered_image": "project-rendered-files/fb91d356-9fef-11ee-8d81-72e77949777c",
                    "render_status": "COMPLETED",
                    "is_changed_from_last_render": false,
                    "failed_reason": "",
                    "psdUrl": null
                }
            ],
            "rendered_count": 1,
            "total_count": 1,
            "rendering_count": 0,
            "failed_count": 0,
            "render_failure_reason": "",
            "rendered_file": "project-rendered-files/fb91d356-9fef-11ee-8d81-72e77949777c",
            "created_by": "5fa91cef803fe80007e484cb",
            "modified_at": "2023-12-21T16:29:21.888822+05:30",
            "render_status": "COMPLETED",
            "created_at": "2023-12-21T16:21:53.363015+05:30",
            "tags": [
                "cadetblue",
                "lightslategray",
                "indianred",
                "whitesmoke",
                "lightsteelblue",
                "*T&C Apply",
                "white",
                "Check loan amount",
                "darkslateblue",
                "gray",
                "darkgray",
                "Today",
                "steelblue",
                "lightpink",
                "midnightblue",
                "silver",
                "black",
                "#XpressCarLoan",
                "Unlock your dream car today",
                "gainsboro",
                "linen",
                "darkslategray",
                "lightgray",
                "dimgray",
                "lavender",
                "crimson",
                "tan",
                "darkolivegreen"
            ],
            "auto_tags": [],
            "file_type": "psd",
            "psdUrl": "https://aiquire-creative-stg-backend.s3.ap-south-1.amazonaws.com/aidesigner/60069086999e4000099dcfaa/44561/test.png",
            "primary_text": "",
            "headline": "",
            "can_manage": true
        }
    },

}

export default aiTemplates;
