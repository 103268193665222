import { SET_FILE_TO_PROJECT_QUEUE, SetCurrentFolderFile, SetUploadedFolder, SET_CURRENT_FOLDER_FILE, SET_UPLOADED_FOLDER, SetUploadFileList, SET_UPLOAD_FILE_LIST, SET_FOLDER_CONTENT_COUNT, SetFolderContentCount, SET_TEMP_FOLDER_CONTENT_COUNT, SetTempFolderContentCount } from './../types/projectFolders';
import {
  FETCH_FOLDERS,
  SET_FOLDERS,
  SET_ACTIVE_FOLDER,
  FolderState,
  SetFolders,
  FetchFolders,
  SetActiveFolder,
  AddProjectToTemplate,
  ADD_FOLDER,
  AddFolder,
  AddSelectedFolder,
  ADD_SECLECTED_FOLDER,
  SetBannerView,
  SetSelectedProject,
  DeleteProjects,
  FetchFiles,
  SET_SELECTED_FOLDER,
  SetSelectedFolder,
  MOVE_FOLDER,
  MoveFolderAction,
  DELETE_FOLDER,
  DeleteFolderAction,
  UPDATE_FOLDER,
  UpdateFolderAction,
  SET_FOLDER_POPUP_PROPS,
  FETCH_PROJECTS,
  FetchProjects,
  SET_PROJECTS,
  SetProjects,
  SET_BANNER_VIEW,
  SET_BANNER_DATA,
  SetBannerData,
  SET_SELECTED_PROJECTS,
  DELETE_PROJECTS,
  MOVE_PROJECTS,
  MoveProjects,
  FETCH_FILES,
  MOVE_FILES,
  DELETE_FILES,
  MoveFiles,
  DeleteFiles,
  ADD_PROJECT_TO_TEMPLATE,
  SetFoldersPageData,
  SET_FOLDERS_PAGE_DATA,
  SET_LOADER,
  SET_FOLDER_LOADER,
  SetFolderLoader,
  SetActiveFolderCreate,
  SET_ACTIVE_FOLDER_CREATE,
  SET_PROJECT_LOADER,
  SetProjectLoader,
  SET_FILTER,
  SetFilter,
  SET_UPLOADED_BANNERS,
  SetUploadedBanners,
  SET_FOLDER_BREADCRUMB,
  SetFolderBreadcrumb,
  SetFileToProjectQueue,
  GetQuickViewBucket
} from "../types";
import { getUnique } from "../../utilities/common-function";

const initialState: FolderState = {
  folders: [],
  activeFolderId: -1,
  activeFolderIdCreate: 0,
  selectedFolders: [],
  selectedProjects: [],
  banners: [],
  bannerData: null,
  isBannerDetailView: false,
  pageData: null,
  folderLoading: false,
  projectLoading: false,
  filter: null,
  uploadedBanners: [],
  folderBreadcrumb: [],
  fileToProjectQueue: null,
  currentFolderFile: 0,
  uploadedFolder: [],
  uploadFileList: [],
  folderContentCount: {},
  tempFolderContentCount: {}
};

export const projectFolderReducer = (
  state = initialState,
  action:
    | SetFolders
    | FetchFolders
    | SetActiveFolder
    | AddFolder
    | AddSelectedFolder
    | SetSelectedFolder
    | MoveFolderAction
    | DeleteFolderAction
    | UpdateFolderAction
    | FetchProjects
    | SetProjects
    | SetBannerView
    | SetBannerData
    | SetSelectedProject
    | DeleteProjects
    | MoveProjects
    | FetchFiles
    | MoveFiles
    | DeleteFiles
    | AddProjectToTemplate
    | SetFoldersPageData
    | SetFolderLoader
    | SetActiveFolderCreate
    | SetProjectLoader
    | SetFilter
    | SetUploadedBanners
    | SetFolderBreadcrumb
    | SetFileToProjectQueue
    | GetQuickViewBucket
    | SetCurrentFolderFile
    | SetUploadedFolder
    | SetUploadFileList
    | SetFolderContentCount
    | SetTempFolderContentCount
): FolderState => {
  switch (action.type) {
    case SET_ACTIVE_FOLDER_CREATE:
      return {
        ...state,
        activeFolderIdCreate: action.payload.folder,
      };
    case SET_FOLDER_LOADER: {
      return {
        ...state,
        folderLoading: action.payload,
      };
    }
    case SET_FOLDERS:
      return {
        ...state,
        folders: action.payload,
      };
    case SET_ACTIVE_FOLDER:
      return {
        ...state,
        activeFolderId: action.payload,
      };
    case ADD_FOLDER:
      return {
        ...state,
        folders: getUnique([...action.payload, ...state.folders]),
      };
    case ADD_SECLECTED_FOLDER:
      return {
        ...state,
        selectedFolders: [...state.selectedFolders, ...action.payload],
      };
    case SET_SELECTED_FOLDER:
      return {
        ...state,
        selectedFolders: action.payload,
      };
    case SET_PROJECTS: {
      return {
        ...state,
        banners: action.payload,
      };
    }
    case SET_BANNER_VIEW: {
      return {
        ...state,
        isBannerDetailView: action.payload,
      };
    }
    case SET_BANNER_DATA: {
      return {
        ...state,
        bannerData: action.payload,
      };
    }
    case SET_SELECTED_PROJECTS: {
      return {
        ...state,
        selectedProjects: action.payload,
      };
    }
    case SET_FOLDERS_PAGE_DATA: {
      return {
        ...state,
        pageData: action.payload,
      };
    }
    case SET_PROJECT_LOADER: {
      return {
        ...state,
        projectLoading: action.payload
      }
    }
    case SET_FILTER: {
      return {
        ...state,
        filter: action.payload
      }
    }
    case SET_UPLOADED_BANNERS: {
      console.log("SET_UPLOADED_BANNERS", action.payload)
      return {
        ...state,
        uploadedBanners: action.payload
      }
    }
    case SET_FILE_TO_PROJECT_QUEUE: {
      return {
        ...state,
        fileToProjectQueue: action.payload
      }
    }
    case SET_FOLDER_BREADCRUMB: {
      return {
        ...state,
        folderBreadcrumb: action.payload,
      }
    }
    case SET_CURRENT_FOLDER_FILE: {
      return {
        ...state,
        currentFolderFile: action.payload
      }
    }
    case SET_UPLOADED_FOLDER: {
      return {
        ...state,
        uploadedFolder: action.payload
      }
    }
    case SET_UPLOAD_FILE_LIST: {
      return {
        ...state,
        uploadFileList: action.payload
      }
    }

    case SET_FOLDER_CONTENT_COUNT: {
      return {
        ...state,
        folderContentCount: action.payload
      }
    }

    case SET_TEMP_FOLDER_CONTENT_COUNT: {
      return {
        ...state,
        tempFolderContentCount: action.payload
      }
    }

    default:
      return state;
  }
};