import React, { useEffect, useState } from "react";
import "./ImageCarousel.scss";
import AiDesignerSavedIcon from "../../assets/img/AiDesignerSavedIcon.png";
import { Input, Tooltip } from "antd";
import AiDesignerDownloadIcon from "../../assets/svg/AiDesignerDownloadIcon.svg";

const Carousel = ({
  selectedImages,
  setSelectedImages,
  setSelectedImage,
  images,
  requestId,
}) => {
  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    setSelectedImage(currentIndex);
  }, []);

  const nextImage = () => {
    const index = (currentIndex + 1) % images.length;
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setSelectedImage(index);
  };

  const prevImage = () => {
    const index = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
    setSelectedImage(index);
  };

  const download = async (url, index) => {
    // async function toDataURL(url) {
    //     const blob = await fetch(url
    //     ).then(res => res.blob());
    //     return URL.createObjectURL(blob);
    // }

    // async function downloadImage() {
    //     const a = document.createElement("a");
    //     a.href = await toDataURL(url);
    //     a.download = `cocreate-${requestId}-${index}.png`;
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    // }

    // await downloadImage()

    fetch(url, {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
      mode: "cors",
      credentials: "omit",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((buffer) => {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `cocreate-${requestId}-${index}.png`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderImages = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    const nextIndex = (currentIndex + 1) % images.length;

    return [images[prevIndex], images[currentIndex], images[nextIndex]].map(
      (src) => (
        <>
          <div
            key={src}
            className={`carousel-image ${
              src === images[currentIndex] ? "center" : ""
            }`}
            style={{
              backgroundImage: `url(${src})`,
            }}
          >
            <div className='img-download'>
              <img
                src={AiDesignerDownloadIcon}
                style={{ borderRadius: "0" }}
                onClick={() => download(src, currentIndex)}
              />
            </div>
            <div className='save-icon-container'>
              {selectedImages.includes(currentIndex) ? (
                <Tooltip
                  placement='bottomRight'
                  title={"Saved to your collection"}
                  key={"Saved to your collection"}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedImages(
                        selectedImages.filter((image) => image != currentIndex)
                      );
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='13'
                      height='16'
                      viewBox='0 0 13 16'
                      fill='none'
                    >
                      <path
                        d='M1.54883 0.5H12.0488C12.4631 0.5 12.7988 0.83579 12.7988 1.25V15.6075C12.7988 15.8145 12.6309 15.9825 12.4238 15.9825C12.3534 15.9825 12.2843 15.9626 12.2246 15.9252L6.79883 12.5235L1.37302 15.9252C1.19755 16.0352 0.966123 15.9822 0.856105 15.8067C0.81868 15.747 0.798828 15.678 0.798828 15.6075V1.25C0.798828 0.83579 1.13462 0.5 1.54883 0.5Z'
                        fill='white'
                      />
                    </svg>
                  </div>
                </Tooltip>
              ) : (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedImages([...selectedImages, currentIndex]);
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='19'
                    height='18'
                    viewBox='0 0 19 18'
                    fill='none'
                  >
                    <path
                      d='M4.54883 1.5H15.0488C15.4631 1.5 15.7988 1.83579 15.7988 2.25V16.6075C15.7988 16.8145 15.6309 16.9825 15.4238 16.9825C15.3534 16.9825 15.2843 16.9626 15.2246 16.9252L9.79883 13.5235L4.37302 16.9252C4.19755 17.0352 3.96612 16.9822 3.85611 16.8067C3.81868 16.747 3.79883 16.678 3.79883 16.6075V2.25C3.79883 1.83579 4.13462 1.5 4.54883 1.5ZM14.2988 3H5.29883V14.5743L9.79883 11.753L14.2988 14.5743V3Z'
                      fill='white'
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </>
      )
    );
  };

  return (
    <div className='p-carousel'>
      <button className='prev-button' onClick={prevImage}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='28'
          height='28'
          viewBox='0 0 28 28'
          fill='none'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M18.4434 7.78908L12.9493 14.0002L18.4434 20.2113L16.752 21.778L9.55447 14.0002L16.752 6.22241L18.4434 7.78908Z'
            fill='#787B80'
          />
        </svg>
      </button>
      <div className='carousel-container'>{renderImages()}</div>
      <button className='next-button' onClick={nextImage}>
        {" "}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='28'
          height='28'
          viewBox='0 0 28 28'
          fill='none'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M9.55469 20.2114L15.0488 14.0003L9.55469 7.78919L11.2461 6.22252L18.4436 14.0003L11.2461 21.7781L9.55469 20.2114Z'
            fill='#0B1829'
          />
        </svg>
      </button>
    </div>
  );
};

export default Carousel;
