import React, { useEffect, useRef, useState, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFolders,
  getStatusSummary,
  getUsersData,
  setFoldersPageData,
  setProjectLoader,
  setFolders,
  setActiveFolder,
  setFolderLoader,
  addFolder,
} from "../../../store/actions";
import { Banner, FolderState, Folder } from "../../../store/types";
import { openToast } from "../../../Toasts";
import {
  detectQueryString,
  drawerRenameTitle,
  getUrlProperties,
} from "../../../utilities/common-function";
import ProjectCard from "../../DashboardSection/ProjectCard";
import EmptyPlaceHolder from "../../shared/EmptyPlaceHolder";
import NoResultsFound from "../../../assets/img/noresultsfound.png";
import { ApprovalAlert, EditAlert, Masonry } from "../../shared";

import { SearchFolders } from "./SearchFolders";
import { useHistory } from "react-router-dom";

const FolderListing: React.FC<{
  type: string;
  filter: any;
  // setPublicLinkDrawerProps: Function;
}> = ({ type, filter }) => {
  const state = useSelector((state: { folders: FolderState }) => state.folders);
  const dispatch = useDispatch();
  const scrollTopRef = useRef(null);
  const { pageData, folderLoading, banners, folders } = state;
  const ids = [];
  const [drawerVisible, setDrawerVisible] = useState("");
  const [currentProject, setCurrentProject] = useState<any>([]);
  const [res, setRes] = useState<any>(folders);
  // let renameTitle=drawerRenameTitle(drawerVisible,type)
  const [showMoreData, setShowMoreData] = useState(5);
  const history = useHistory();
  const showMore = () => {
    if (showMoreData === 5) {
      setShowMoreData(res.length);
    } else if (showMoreData > 5) {
      setShowMoreData(5);
    }
  };
  const changeActiveFolderInRouteForward = useCallback((id: number) => {
    const { baseUrl, search } = getUrlProperties();
    // const newSearch = search.replace("project_type=project", "");
    const newSearch = search;
    // let newurl =
    //   `/cocreate/homepage` +
    //   (newSearch ? "?" + newSearch : "") +
    //   (newSearch.length === 0 ? "?folder=" + id : "-" + id);
    let newurl = `/cocreate/homepage?folder=${id}`;
    history.push(newurl);

    // window.location.reload();
  }, []);
  const fetchAllFolders = (page: number) => {
    if (!folderLoading) dispatch(setFolderLoader(true));
    dispatch(
      fetchFolders({ type, ...filter }, (res: any, error: boolean) => {
        if (!error) {
          dispatch(
            setFoldersPageData({
              count: res.count,
              current_page: res.current_page,
              page_size: res.page_size,
              pages: res.pages,
            })
          );
          dispatch(setFolderLoader(false));
          setRes(res.results);
          dispatch(addFolder(res.results));
          let userIds = res.results.reduce((acc: any, value: any) => {
            if (!acc.includes(value.auth_user_id)) {
              acc.push(value.auth_user_id);
            }
            return acc;
          }, []);
          if (userIds.length > 0) {
            dispatch(setProjectLoader(true));
            dispatch(
              getUsersData(
                { userIds: userIds },
                (userres: any, usererr: boolean) => {
                  if (!usererr) {
                    let { users } = userres.data;
                    res.map((folder: any, index: number) => {
                      folder.auth_user_id =
                        users.find(
                          (user: any) => user._id === folder.created_by
                        ) || "";
                    });
                  }
                  dispatch(setFolderLoader(false));
                }
              )
            );
          }
        }
      })
    );
  };

  useEffect(() => {
    if (type === "project") {
      fetchAllFolders(1);
    }
    return () => {
      dispatch(setFolders([]));
      // dispatch(setFoldersPageData(null))
    };
  }, [filter]);

  return (
    <div className='folder-search-data'>
      {folderLoading ? (
        <div className='folder-container'>
          {new Array(5).fill(null).map((f: any, i: number) => (
            <div className='folder flex pulsate' key={i}></div>
          ))}
        </div>
      ) : (
        //   <InfiniteScroll
        //     dataLength={pageData.length}
        //     next={() => fetchAllFolders(pageData.length + 1)}
        //     hasMore={true}
        //     loader={<h4>Loading...</h4>}
        //     endMessage={<p>No more folders to load</p>}
        //     scrollableTarget="scrollableDiv"
        //   >
        <div className='folder-container'>
          {res.length ? (
            res
              .slice(0, showMoreData)
              .map((folder: Folder, index) => (
                <SearchFolders
                  folder={folder}
                  index={index}
                  type={type}
                  setDrawerVisible={setDrawerVisible}
                  canCreate={false}
                  setCurrentProject={setCurrentProject}
                  folders={res}
                  changeActiveFolderInRouteForward={
                    changeActiveFolderInRouteForward
                  }
                />
              ))
          ) : (
            <div className='no-data-placement'>
              No search results under Folders
            </div>
          )}
          {res?.length >= 5 && (
            <div className='view-more-folders' onClick={showMore}>
              {showMoreData > 5
                ? `View only 5 folders`
                : `View all ${res?.length || 0} folders`}
            </div>
          )}
        </div>
        //   </InfiniteScroll>
      )}
    </div>
  );
};

export default FolderListing;
