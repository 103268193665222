import axios from 'axios';
import { put, takeLatest, all, call } from 'redux-saga/effects';
import { APPROVAL_URI } from '../../utilities/paths';
import {
  SendForApproval, SEND_FOR_APPROVAL, GetStatusSummary, GET_STATUS_SUMMARY, GetApprovalStatus,
  GET_APPROVAL_STATUS, DuplicateApprovalRound, DUPLICATE_APPROVAL_ROUND, DeleteApprovalRound,
  DELETE_APPROVAL_ROUND, EditApprovalRound, EDIT_APPROVAL_ROUND, AddApprovalRound, ADD_APPROVAL_ROUND,
  FetchApprovalComments, FETCH_APPROVAL_COMMENTS, SendApprovalComment, SEND_APPROVAL_COMMENT, EditApprovalComment,
  EDIT_APPROVAL_COMMENT, DeleteApprovalComment, DELETE_APPROVAL_COMMENT, FetchPendingApproval,
  FETCH_PENDING_APPROVALS, AddUserToApprovalRound, DeleteUserFromApprovalRound, ADD_USER_TO_APPROVAL_ROUND,
  DELETE_USER_FROM_APPROVAL_ROUND, MarkApprovalStatus, MARK_APPROVAL_STATUS, FetchMyApprovalStatus,
  FETCH_MY_APPROVAL_STATUS, CancelApprovalRequest, CANCEL_APPROVAL_REQUEST, ContinueApproval, CONTINUE_APPROVAL,
  FetchPendingApprovalCount, FETCH_PENDING_APPROVAL_COUNT, FetchApprovalsList, FETCH_APPROVALS_LIST, GET_SUMMARY_SUCCESS,
  ReSendForApproval, RESEND_FOR_APPROVAL, FolderApproval, FOLDER_APPROVAL
} from '../types';
import HttpService from '../../HttpService/HttpService';
import { updateApprovalRoundsAPI, approvalCommentsAPI, approvalStatusSummary, approvalStatus, approvalRequest, pendingApproval, pendingApprovalCount, approvalsAPI, approvalRequestAgain, folderApprovalAPI } from '../../MappedAPI/MappedAPI';
import { store } from '../store';
import { openToast } from '../../Toasts';

function* sendForApproval(action: SendForApproval) {
  let { payload: { entity_id, workflow_id, note, entity_type }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let response = yield call(HttpService.post, APPROVAL_URI, `${approvalRequest}`, TOKEN, { entity_id, workflow_id, note, entity_type })

    if (!response.data.error) {
      openToast('success', response?.data?.data?.message || "Sent for approval successfully")
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error', error?.response?.data?.message || "Something Went Wrong!")
    callback && callback(error.response, true);
  }
}

function* folderApproval(action: FolderApproval) {
  let { payload: { entity_ids, workflow_id, note, entity_type }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let response = yield call(HttpService.post, APPROVAL_URI, `${folderApprovalAPI}`, TOKEN, { entity_ids, workflow_id, note, entity_type })
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error', error?.response?.data?.message || "Something Went Wrong!")
    callback && callback(error.response, true);
  }
}

function* getStatusSummary(action: GetStatusSummary) {
  let { payload: { ids, entity_type }, callback } = action;
  if (ids.length === 0) { callback && callback({ data: { results: [] } }, false); return; }
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `?entity_ids=${ids.join(',')}${entity_type ? '&entity_type=' + entity_type : ''}`;
    let response = yield call(HttpService.get, APPROVAL_URI, `${approvalStatusSummary}${params}`, TOKEN)

    if (!response.data.error) {
      callback && callback(response.data, false);
      put({ type: GET_SUMMARY_SUCCESS, payload: response.data })
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* getApprovalStatus(action: GetApprovalStatus) {
  let { payload: { entity_id, entity_type }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `?entity_id=${entity_id}${entity_type ? '&entity_type=' + entity_type : ''}`;
    let response = yield call(HttpService.get, APPROVAL_URI, `${approvalStatus}${params}`, TOKEN)

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* duplicateApprovalRound(action: DuplicateApprovalRound) {
  let { payload: { entity_id, round_id }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `/${entity_id}/round/${round_id}/duplicate`;
    let response = yield call(HttpService.post, APPROVAL_URI, `${updateApprovalRoundsAPI}${params}`, TOKEN, {})

    if (!response.data.error) {
      openToast('success', response?.data?.data?.message || "Approval round duplicated successfully")
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error', error?.response?.data?.message || "Something Went Wrong!")
    callback && callback(error.response, true);
  }
}

function* deleteApprovalRound(action: DeleteApprovalRound) {
  let { payload: { entity_id, round_id }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `/${entity_id}/round/${round_id}`;
    let response = yield call(HttpService.delete, APPROVAL_URI, `${updateApprovalRoundsAPI}${params}`, TOKEN)

    if (!response.data.error) {
      openToast('success', response?.data?.data?.message || "Approval round deleted successfully")
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error', error?.response?.data?.message || "Something Went Wrong!")
    callback && callback(error.response, true);
  }
}

function* editApprovalRound(action: EditApprovalRound) {
  let { payload: { entity_id, round_id, round }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `/${entity_id}/round/${round_id}`;
    let response = yield call(HttpService.put, APPROVAL_URI, `${updateApprovalRoundsAPI}${params}`, TOKEN, round)

    if (!response.data.error) {
      openToast('success', response?.data?.data?.message || "Approval round updated successfully")
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error', error?.response?.data?.message || "Something Went Wrong!")
    callback && callback(error.response, true);
  }
}

function* addApprovalRound(action: AddApprovalRound) {
  let { payload: { entity_id, round }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `/${entity_id}/round`;
    let response = yield call(HttpService.post, APPROVAL_URI, `${updateApprovalRoundsAPI}${params}`, TOKEN, round)

    if (!response.data.error) {
      openToast('success', response?.data?.data?.message || "Approval round added successfully")
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error', error?.response?.data?.message || "Something Went Wrong!")
    callback && callback(error.response, true);
  }
}

function* fetchApprovalComments(action: FetchApprovalComments) {
  let { payload: { entity_id, page, page_size, entity_type, order_by, child_id, resolution_status }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `?entity_id=${entity_id}&page=${page}&page_size=${page_size}&entity_type=${entity_type}${order_by ? "&order_by=" + order_by : ""}${child_id !== -1 ? "&child_id=" + child_id : ""}${resolution_status !== -1 ? "&resolution_status=" + resolution_status : ""}`;
    let response = yield call(HttpService.get, APPROVAL_URI, `${approvalCommentsAPI}${params}`, TOKEN)

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* sendApprovalComment(action: SendApprovalComment) {
  let { payload: { entity_id, comment, entity_type }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let data = new FormData();
    data.append("entity_id", (entity_id).toString());
    data.append("comment", comment);
    data.append("entity_type", entity_type);
    let response = yield call(HttpService.post, APPROVAL_URI, `${approvalCommentsAPI}`, TOKEN, data)

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* editApprovalComment(action: EditApprovalComment) {
  let { payload: { comment_id, comment }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `/${comment_id}`
    let response = yield call(HttpService.put, APPROVAL_URI, `${approvalCommentsAPI}${params}`, TOKEN, { comment })

    if (!response.data.error) {
      openToast('success', response?.data?.data?.message || "Comment updated successfully")
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error', error?.response?.data?.message || "Something Went Wrong!")
    callback && callback(error.response, true);
  }
}

function* deleteApprovalComment(action: DeleteApprovalComment) {
  let { payload, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `/${payload}`
    let response = yield call(HttpService.delete, APPROVAL_URI, `${approvalCommentsAPI}${params}`, TOKEN)

    if (!response.data.error) {
      openToast('success', response?.data?.data?.message || "Comment deleted successfully")
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error', error?.response?.data?.message || "Something Went Wrong!")
    callback && callback(error.response, true);
  }
}

function* fetchPendingApproval(action: FetchPendingApproval) {
  let { payload: { page, page_size, user_id }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  const product_id = store.getState().roles.selectedProductId;
  try {
    let params = `?page=${page}&page_size=${page_size}&user_id=${user_id}&product_id=${product_id}`
    let response = yield call(HttpService.get, APPROVAL_URI, `${pendingApproval}${params}`, TOKEN)

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* addUserToApprovalRound(action: AddUserToApprovalRound) {
  let { payload: { entity_id, userId, roundId }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `/${entity_id}/round/${roundId}/users`
    let response = yield call(HttpService.post, APPROVAL_URI, `${updateApprovalRoundsAPI}${params}`, TOKEN, { user_id: userId })

    if (!response.data.error) {
      openToast('success', response?.data?.data?.message || "User added to approval round!")
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error', error?.response?.data?.message || "Something Went Wrong!")
    callback && callback(error.response, true);
  }
}

function* deleteUserFromApprovalRound(action: DeleteUserFromApprovalRound) {
  let { payload: { entity_id, userId, roundId }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `/${entity_id}/round/${roundId}/users`
    let response = yield call(HttpService.delete, APPROVAL_URI, `${updateApprovalRoundsAPI}${params}`, TOKEN, { user_id: userId })

    if (!response.data.error) {
      openToast('success', response?.data?.data?.message || "User deleted from round!")
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error', error?.response?.data?.message || "Something Went Wrong!")
    callback && callback(error.response, true);
  }
}

function* markApprovalStatus(action: MarkApprovalStatus) {
  let { payload: { entity_id, userId, status, entity_type }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `/api/mark-approval-status?user_id=${userId}`
    let response = yield call(HttpService.post, APPROVAL_URI, `${params}`, TOKEN, { entity_id, status, entity_type })

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* fetchMyApprovalStatus(action: FetchMyApprovalStatus) {
  let { payload: { entity_id, user_id, entity_type }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `/api/my-approval-status?entity_id=${entity_id}&user_id=${user_id}&entity_type=${entity_type}`
    let response = yield call(HttpService.get, APPROVAL_URI, `${params}`, TOKEN)

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}


function* cancelApprovalRequest(action: CancelApprovalRequest) {
  let { payload: { entity_id, entity_type }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `/api/cancel-approval`;
    let response = yield call(HttpService.delete, APPROVAL_URI, `${params}`, TOKEN, { entity_id, entity_type })

    if (!response.data.error) {
      openToast('success', response?.data?.data?.message || "Approval request canceled!")
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error', error?.response?.data?.message || "Something Went Wrong!")
    callback && callback(error.response, true);
  }
}

function* continueApproval(action: ContinueApproval) {
  let { payload: { entity_id, entity_type }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `/api/continue-approval-workflow`
    let response = yield call(HttpService.post, APPROVAL_URI, `${params}`, TOKEN, { entity_id, entity_type })

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* fetchPendingApprovalCount(action: FetchPendingApprovalCount) {
  let { payload: { user_id, product_id }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let params = `?user_id=${user_id}&product_id=${product_id}`
    let response = yield call(HttpService.get, APPROVAL_URI, `${pendingApprovalCount}${params}`, TOKEN)

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* fetchApprovalsList(action: FetchApprovalsList) {
  let { payload: { page_size, page, entity_type, status }, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  const product_id = store.getState().roles.selectedProductId;

  try {
    let params = `?page=${page}&page_size=${page_size}${status ? '&status=' + status : ''}${entity_type ? '&entity_type=' + entity_type : ''}&product_id=${product_id}`
    let response = yield call(HttpService.get, APPROVAL_URI, `${approvalsAPI}${params}`, TOKEN)

    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
  }
}

function* reSendForApproval(action: ReSendForApproval) {
  let { payload, callback } = action;
  const TOKEN: any = store.getState().authentication.token;
  try {
    let response = yield call(HttpService.post, APPROVAL_URI, `${approvalRequestAgain}`, TOKEN, payload)

    if (!response.data.error) {
      openToast('success', response?.data?.data?.message || "Sent for approval again!")
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    openToast('error', error?.response?.data?.message || "Unable to resend for approval!")
    callback && callback(error.response, true);
  }
}

export function* approvalWatcher() {
  yield takeLatest(SEND_FOR_APPROVAL, sendForApproval)
  yield takeLatest(FOLDER_APPROVAL, folderApproval)
  yield takeLatest(GET_STATUS_SUMMARY, getStatusSummary)
  yield takeLatest(GET_APPROVAL_STATUS, getApprovalStatus)
  yield takeLatest(DUPLICATE_APPROVAL_ROUND, duplicateApprovalRound)
  yield takeLatest(DELETE_APPROVAL_ROUND, deleteApprovalRound)
  yield takeLatest(EDIT_APPROVAL_ROUND, editApprovalRound)
  yield takeLatest(ADD_APPROVAL_ROUND, addApprovalRound)
  yield takeLatest(FETCH_APPROVAL_COMMENTS, fetchApprovalComments)
  yield takeLatest(SEND_APPROVAL_COMMENT, sendApprovalComment)
  yield takeLatest(EDIT_APPROVAL_COMMENT, editApprovalComment)
  yield takeLatest(DELETE_APPROVAL_COMMENT, deleteApprovalComment)
  yield takeLatest(FETCH_PENDING_APPROVALS, fetchPendingApproval)
  yield takeLatest(ADD_USER_TO_APPROVAL_ROUND, addUserToApprovalRound)
  yield takeLatest(DELETE_USER_FROM_APPROVAL_ROUND, deleteUserFromApprovalRound)
  yield takeLatest(MARK_APPROVAL_STATUS, markApprovalStatus)
  yield takeLatest(FETCH_MY_APPROVAL_STATUS, fetchMyApprovalStatus)
  yield takeLatest(CANCEL_APPROVAL_REQUEST, cancelApprovalRequest)
  yield takeLatest(CONTINUE_APPROVAL, continueApproval)
  yield takeLatest(FETCH_PENDING_APPROVAL_COUNT, fetchPendingApprovalCount)
  yield takeLatest(FETCH_APPROVALS_LIST, fetchApprovalsList)
  yield takeLatest(RESEND_FOR_APPROVAL, reSendForApproval)
}