import React, { useEffect } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AiquireHomepage from "./Pages/AiquireHomepage/AiquireHomepage";
import AiquireLibraryOnboarding from "./Pages/AiquireLibraryOnboarding";
import AdvanceSearch from "./Pages/AdvanceSearch";
import AiquireViewProfile from "./Pages/AiquireViewProfile";
import AiquireCreate from "./Pages/AiquireCreate/AiquireCreate";
import AiquireLibrary from "./Pages/AiquireLibrary";
import AiquireBannerDetail from "./Pages/AiquireBannerDetail";
import ApprovalWorkflow from "./Pages/ApprovalWorkFlow";
import AiquireCreateSet from "./Pages/AiquireCreate/AiquireCreateSet";
import AiquireCreateFlow from "./Pages/AiquireCreateFlow/AiquireCreateFlow";
import NotFound from "./Pages/PageNotFound/PageNotFound";
import NewWorkFlow from "./Pages/NewWorkFlow";
import WorkFlowDetail from "./Pages/WorkFlowDetail";
import AiquirePendingApproval from "./Pages/AiquirePendingApproval";
import AiquireMembersAndProducts from "./Pages/AiquireMembersAndProducts";
import HyperPersonalize from "./Pages/HyperPersonalize/HyperPersonalize";
import HyperPersonalizeSingle from "./Pages/AiquireCreate/HyperPersonalizeSingle";
import AiquireRoles from "./Pages/AiquireRoles";
import AiquireLogin from "./Pages/AiquireLogin";
import HyperPersonlizeSelect from "./Pages/HyperPersonalizeSelect";
import HyperPersonlizeLayers from "./Pages/HyperPersonalizeLayers";
import HyperPersonalizeDesigns from "./Pages/HyperPersonalizeDesigns";
import HyperProjectDetail from "./Pages/AiquireHyperProjectDetail";
import AiquireDesignOnboarding from "./Pages/AiquireDesignOnboarding";
import AiquireCreativeAutomationOnboarding from "./Pages/AiquireCreativeAutomationOnboarding";
import AiquireCAChooseDesign from "./Pages/AiquireCAChooseDesign/AiquireCAChooseDesign";
import AiquireRegister from "./Pages/AiquireRegister";
import AiquireResetPassword from "./Pages/AiquireResetPassword";
import AiquireFeedBack from "./Pages/FeedBack";
import SimplifyCommunication from "./Pages/SimplifyCommunication";
import PrivateRoute from "./PrivateRoute";

import "./Routes.scss";
import PublicProject from "./Pages/PublicProject";
import ProgressiveModals from "./components/ProgressiveModals";
import RedirectRoute from "./Pages/RedirectRoute";
import AIDesigner from "./Pages/AIDesigner";
import VLEStatus from "./Pages/VLEStatus";
import VLEStatusTable from "./Pages/VLEStatusTable";

export default function Routes() {
  return (
    <div>
      <HashRouter>
        <Switch>
          {/* public routes */}
          <Route path='/redirect' component={RedirectRoute} exact />
          <Route path='/login' component={AiquireLogin} exact />
          <Route path='/register' component={AiquireRegister} exact />
          <Route
            path='/reset-password'
            component={AiquireResetPassword}
            exact
          />
          <Route
            path='/cocreate/public/banner-detail'
            component={AiquireBannerDetail}
            exact
          />
          <Route
            path='/cocreate/public/creative-automation/banner-detail'
            component={HyperProjectDetail}
            exact
          />
          <Route
            path='/cocreate/public/:projectType/:projectLinkKey'
            component={PublicProject}
            exact
          />

          {/* private routes */}
          <PrivateRoute path='/' component={AiquireHomepage} exact />
          <PrivateRoute
            path='/cocreate/vle-status'
            component={VLEStatus}
            exact
          />
          <PrivateRoute
            path='/cocreate/vle-status/:folder_name/:url_hash'
            component={VLEStatusTable}
            exact
          />
          <PrivateRoute
            path='/cocreate/vle-logs/:folder_name/:url_hash'
            component={VLEStatusTable}
            exact
          />
          <PrivateRoute
            path='/cocreate/simplify-communication/:product'
            component={SimplifyCommunication}
            exact
          />
          <PrivateRoute
            path='/cocreate/feedback'
            component={AiquireFeedBack}
            exact
          />
          <PrivateRoute
            path='/cocreate/homepage'
            component={AiquireHomepage}
            exact
          />
          <PrivateRoute
            path='/cocreate/advance-search'
            component={AdvanceSearch}
            exact
          />
          <PrivateRoute
            path='/cocreate/view-profile'
            component={AiquireViewProfile}
            exact
          />
          <PrivateRoute
            path='/cocreate/create'
            component={AiquireCreateFlow}
            exact
          />
          <PrivateRoute
            path='/cocreate/AIDesigner/:type'
            component={AIDesigner}
            exact
          />
          <PrivateRoute
            path='/cocreate/library/:librarytype'
            component={AiquireLibrary}
            exact
          />
          <PrivateRoute
            path='/cocreate/library-onboarding'
            component={AiquireLibraryOnboarding}
            exact
          />
          <PrivateRoute
            path='/cocreate/banner-detail'
            component={AiquireBannerDetail}
            exact
          />
          <PrivateRoute
            path='/cocreate/workflows'
            component={ApprovalWorkflow}
            exact
          />
          <PrivateRoute
            path='/cocreate/workflow/dashboard'
            component={ApprovalWorkflow}
            exact
          />
          <PrivateRoute
            path='/cocreate/workflow/new'
            component={NewWorkFlow}
            exact
          />
          <PrivateRoute
            path='/cocreate/workflow/:workflowid'
            component={WorkFlowDetail}
            exact
          />
          <PrivateRoute
            path='/cocreate/approval/pending'
            component={AiquirePendingApproval}
            exact
          />
          <PrivateRoute
            path='/cocreate/members'
            component={AiquireMembersAndProducts}
            exact
            productIndependent
          />
          <PrivateRoute
            path='/cocreate/products'
            component={AiquireMembersAndProducts}
            exact
            productIndependent
          />
          <PrivateRoute
            path='/cocreate/roles'
            component={AiquireRoles}
            exact
            productIndependent
          />
          <PrivateRoute
            path='/cocreate/editor/:projectId'
            component={AiquireCreate}
            exact
          />
          <PrivateRoute
            path='/cocreate/set/:projectIdSet'
            component={AiquireCreateSet}
            exact
          />
          <PrivateRoute
            path='/cocreate/creative-automation/designs'
            component={HyperPersonalizeDesigns}
            exact
          />
          <PrivateRoute
            path='/cocreate/creative-automation/select/:projectId'
            component={HyperPersonlizeSelect}
            exact
          />
          <PrivateRoute
            path='/cocreate/creative-automation/layers/:projectId'
            component={HyperPersonlizeLayers}
            exact
          />
          <PrivateRoute
            path='/cocreate/creative-automation/detail/'
            component={HyperProjectDetail}
            exact
            productIndependent
          />
          <PrivateRoute
            path='/cocreate/creative-automation/editor/:projectId'
            component={HyperPersonalize}
            exact
          />
          <PrivateRoute
            path='/cocreate/creative-automation/editor/single/:projectId/:sizeId/:combination'
            component={HyperPersonalizeSingle}
            exact
          />
          <PrivateRoute
            path='/cocreate/design-onboarding'
            component={AiquireDesignOnboarding}
            exact
          />
          <PrivateRoute
            path='/cocreate/creative-automation-onboarding'
            component={AiquireCreativeAutomationOnboarding}
            exact
          />
          <PrivateRoute
            path='/cocreate/creative-automation-choose-design'
            component={AiquireCAChooseDesign}
            exact
          />
          <PrivateRoute component={NotFound} />
        </Switch>
        <ProgressiveModals />
      </HashRouter>
      <ToastContainer
        position='bottom-center'
        autoClose={3000}
        style={{ zIndex: 9999999999999 }}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        transition={Zoom}
        closeButton={false}
      />
    </div>
  );
}
