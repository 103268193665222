import moment from "moment";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApprovalState, FolderState } from "../../../../../store/types";
import { openToast } from "../../../../../Toasts";
import { CSSTransition } from "react-transition-group";
import CheckCircleOutlined from "../../../../../assets/svg/check-circle-outline.svg";
import CheckCircleOutlinedFilled from "../../../../../assets/svg/check-circle-outline-filled.svg";
import { Avatar } from "../../../../shared";
import { Dropdown, Menu, MenuItem } from "pyxis-ui-kit";
import Icon, {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import EditorInput from "../../../../shared/EditorInput/index";
import activePin from '../../../../../assets/img/activePin.png';
import { isItImageFileType } from "../../../../../utilities/common-function";

interface CommentInterface {
  data: any;
  index: number;
  isOtherUser: boolean;
  handleActionClick: Function;
  userSuggestions: any[];
  updateComment?: Function;
  canComment: boolean;
  client: WebSocket;
  selectedBanner: any;
  commentIndex: number;
  setCommentIndex: Function;
  replyCommentIndex: number;
  setReplyCommentIndex: Function;
  setInFocus: Function;
}

const Comment: React.FunctionComponent<CommentInterface> = ({
  data,
  index,
  handleActionClick,
  isOtherUser,
  userSuggestions,
  updateComment,
  canComment,
  client,
  selectedBanner,
  commentIndex,
  setCommentIndex,
  replyCommentIndex,
  setReplyCommentIndex,
  setInFocus
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [resolutionChanged, setResolutionChanged] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [isReplyMode, setReplyMode] = useState(false);
  const dispatch = useDispatch();
  const editorInputRef = useRef<any>(null);
  const editorReplyRef = useRef<any>(null);
  let hours = moment().diff(moment(data.modified_at), "hours");
  let modified_at = moment(data.modified_at);
  let created_at = moment(data.created_at);
  let duration = moment.duration(modified_at.diff(created_at));
  let comment: any = data.comment ? data.comment : data.comments[0].comment;
  const [isReadMore, setIsReadMore] = useState(true);
  const shouldRenderChild = useDelayUnmount(resolutionChanged, 500);
  const commentResolution = useSelector(
    (state: { approval: ApprovalState }) => state.approval.comments_resolution
  );
  const state = useSelector(
    (state: { approval: ApprovalState }) => state.approval
  );
  const banners = useSelector(
    (state: { folders: FolderState }) => state.folders.bannerData.banners
  );
  const { bannerData } = useSelector(
    (state: { folders: FolderState }) => state.folders
  );
  const { comments } = state;

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const handleEditClick = useCallback(
    (text: string) => {
      if (!text.trim()) {
        openToast("info", "Comment cannot be empty!");
        return;
      }
      if(client.readyState === 1) {
        client.send(
          JSON.stringify({
            comment: text,
            comment_id: data.id,
            action: "comment_updated",
          })
        );
      }
      setEditMode(false);
    },
    [data.id, setEditMode, index, client]
  );

  const handlePressEnter = useCallback(() => {
    setInFocus(false);
    if (editorInputRef?.current?.isInitialValueChanged()) {
      handleEditClick(editorInputRef?.current?.getRawText());
    } else {
      openToast("warn", "Please make some changes before saving");
    }
  }, [handleEditClick]);

  const handleReply = useCallback(
    (text: string) => {
      if (!text.trim()) {
        openToast("info", "Comment cannot be empty!");
        return;
      }
      let child_id = selectedBanner !== -1 ? banners[selectedBanner]?.id : undefined;
      if(client.readyState === 1) {
        client.send(
          JSON.stringify({
            comment: text,
            parent_comment_id: data.id,
            child_id
          })
        );
      }
      setReplyMode(false);
    },
    [data.id, setReplyMode, index, selectedBanner, client]
  );

  const handleReplyPressEnter = useCallback(() => {
    setInFocus(false);
    handleReply(editorReplyRef?.current?.getRawText());
  }, [handleReply]);

  function useDelayUnmount(resolutionChanged: boolean, delayTime: number) {
    useEffect(() => {
      let timeoutId: number;
      if (resolutionChanged) {
        timeoutId = setTimeout(
          () => changeCommentResolution(data.resolved_status),
          delayTime
        );
      }
      return () => clearTimeout(timeoutId);
    }, [resolutionChanged, delayTime]);
  }

  const changeCommentResolution = (resolved_status: boolean) => {
    if(!data.parent_comment_id) {
      setReplyCommentIndex(-1);
    }
    data.resolved_status = !resolved_status;
    comments.forEach((c:any) => {
      if(c.parent_comment_id === data.id && client && client.readyState === 1) {
        client.send(
          JSON.stringify({
            comment: c.comment,
            comment_id: c.id,
            action: "resolution_updated",
            resolution_status: data.resolved_status,
          })
        );
        if(commentResolution === -1) {
          setResolutionChanged(!resolutionChanged);
        }
      }
    });
    if(client && client.readyState === 1) {
      client.send(
        JSON.stringify({
          comment: comment,
          comment_id: data.id,
          action: "resolution_updated",
          resolution_status: data.resolved_status,
        })
      );
      if (commentResolution === -1) {
        setResolutionChanged(!resolutionChanged);
      }
      openToast("success", "Comment resolution has been updated!");
    }
  };

  useEffect(() => {
    comments.forEach((c:any) => {
      if(c.parent_comment_id === data.id && client && client.readyState === 1 && commentResolution !== -1) {
        client.send(
          JSON.stringify({
            comment: c.comment,
            comment_id: c.id,
            action: "resolution_updated",
            resolution_status: data.resolved_status,
          })
        );
        if(commentResolution === -1) {
          setResolutionChanged(!resolutionChanged);
        }
      }
    });
  }, [comments.length, client]);

  return (
    <CSSTransition
      timeout={10}
      in={resolutionChanged}
      className={
        commentResolution !== -1 ? `comment comment-animation ${(data.id === commentIndex || data.id === replyCommentIndex) ? "active" : ""}` : `comment ${(data.id === commentIndex || data.id === replyCommentIndex) ? "active" : ""}`
      }
    >
      <div className={isOtherUser ? "comment other-user" : "comment"}>
        <>
          <div 
            className={(!isEditMode && !isReplyMode) ? "comment-container active" : "comment-container"}
            onClick={() => {
              if(!isEditMode && !isReplyMode) {
                setCommentIndex(data.id);
              }
            }}>
            <div className="edit-comment-detail">
              <Avatar
                size="small"
                initials={data.user_id ? data.user_id.firstName[0] : ""}
                showInitials={
                  !!(data.user_id ? !data.user_id?.profilePicture : true)
                }
                backgroundColor={data.user_id?.profileColour}
                src={data.user_id ? data.user_id?.profilePicture : ""}
              />
              <div className="edit-section">
                <b className="text">
                  {data.user_id
                    ? data.user_id.firstName + " " + data.user_id.lastName
                    : ""}
                </b>
              </div>
              <div 
                className="actionalble-section" 
                onClick={(e:any) => {
                  e.stopPropagation(); 
                  setCommentIndex(-1);
                }}>
                {!data.parent_comment_id && <div
                  className="resolution-section"
                  onClick={(e:any) => {
                    e.stopPropagation();
                    setResolutionChanged(!resolutionChanged);
                  }}
                >
                  {data.resolved_status === true ? (
                    <img src={CheckCircleOutlinedFilled} />
                  ) : (
                    <img src={CheckCircleOutlined} />
                  )}
                </div>}
                {!isOtherUser && canComment && (
                  <Dropdown
                    trigger={["click"]}
                    getPopupContainer={(trigger) =>
                      trigger.parentNode as HTMLElement
                    }
                    visible={isOpen}
                    onVisibleChange={(flag: boolean) => {
                      setIsOpen(flag);
                    }}
                    overlay={
                      <Menu>
                        <MenuItem
                          className="action-edit"
                          onClick={() => {
                            setEditMode(true);
                            setReplyMode(false);
                            setIsOpen(false);
                          }}
                        >
                          <EditOutlined className="menu-icon" />
                          Edit
                        </MenuItem>
                        <MenuItem
                          className="comment-delete"
                          onClick={() => {
                            handleActionClick("delete", data.id);
                          }}
                        >
                          <DeleteOutlined className="menu-icon" />
                          Delete
                        </MenuItem>
                      </Menu>
                    }
                  >
                    <EllipsisOutlined className="comment-menu" />
                  </Dropdown>
                )}
              </div>
            </div>
            <div className="comment-info">
              {isEditMode ? (
                <div className="comment-editable" onMouseDown={() => setInFocus(true)} onBlur={() => setEditMode(false)}>
                  <EditorInput
                    ref={editorInputRef}
                    initialValue={data.comment.replace("\n", "<pre/>")}
                    placeholder="Write your reply here"
                    onPressEnter={handlePressEnter}
                    mentions={userSuggestions}
                    tagsArr={data.tagsArr || []}
                    className="edit-editor"
                    onEscape={() => {setInFocus(false); setEditMode(false);}}
                    setInFocus={setInFocus}
                  />
                </div>
              ) : (
                <p
                  className="comment-text"
                  onClick={toggleReadMore}
                  dangerouslySetInnerHTML={{
                    __html: isReadMore
                      ? comment.slice(0, 60) +
                        `<span class="read-or-hide">${
                          comment.length > 60
                            ? `<span class="ellipsis">...</span>` + " Read more"
                            : ""
                        }</span>`
                      : comment +
                        `<span class="read-or-hide">${
                          comment.length > 60 ? " Read less" : ""
                        }</span>`,
                  }}
                ></p>
              )}
              {isItImageFileType(bannerData?.type, bannerData?.file_type) && data.pin_number && <div className="pin-container">
                <img className={`comment-pin ${data.pin_number > 9 ? "large" : ""}`} src={activePin} />
                <span className={`pin-number ${data.pin_number > 9 ? "large" : ""}`}>{data.pin_number}</span>
              </div>}
            </div>
            {!isEditMode && (
              <div className={`action-row ${data.pin_number ? 'with-pin' : ''}`}>
                <div className="actions">
                  <div className="last-modified">
                    {hours <= 1
                      ? moment(data.modified_at)
                          .startOf("minute")
                          .fromNow()
                      : hours <= 24
                      ? moment(data.modified_at)
                          .startOf("hour")
                          .fromNow()
                      : hours <= 48
                      ? "Yesterday"
                      : moment(data.modified_at).isSame(new Date(), "week")
                      ? moment(data.modified_at).format("dddd")
                      : moment(data.modified_at).format("ll")}
                  </div>
                  {!isReplyMode && (replyCommentIndex === -1) && 
                    <div 
                      className="reply-text" 
                      onClick={(e:any) => {
                        e.stopPropagation();
                        setReplyMode(true); 
                        setCommentIndex(-1);
                      }}>
                        Reply
                  </div>}
                </div>
              </div>
            )}
            {isReplyMode && <div className="reply-container">
              <div className="reply-comment-text" onBlur={() => setReplyMode(false)}>
                <EditorInput
                  ref={editorReplyRef}
                  placeholder="Write your reply here"
                  onPressEnter={handleReplyPressEnter}
                  mentions={userSuggestions}
                  className="reply-editor"
                  onEscape={() => {setInFocus(false); setReplyMode(false);}}
                  setInFocus={setInFocus}
                />
              </div>
            </div>}
            {!isReplyMode && data.id !== replyCommentIndex && data.replyCount > 0 &&
              <div className="reply-count-container">
                <div 
                  className="reply-count" 
                  onClick={(e:any) => {
                    e.stopPropagation();
                    setCommentIndex(-1);
                    setReplyCommentIndex(data.id);
                  }}>
                    <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.0996 7.2998L12.1996 9.3998L10.0996 11.4998" stroke="#8090E7" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M4.5 4.5V7.61818C4.5 8.09075 4.68437 8.54396 5.01256 8.87812C5.34075 9.21227 5.78587 9.4 6.25 9.4H11.5" stroke="#8090E7" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p>{data.replyCount}{data.replyCount === 1 ? " reply" : " replies"}</p>
                </div>
              </div>
            }
          </div>
        </>
      </div>
    </CSSTransition>
  );
};

export default Comment;