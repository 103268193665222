import React, { FC, useRef, useEffect, useState } from "react";
import { Drawer, Button } from "pyxis-ui-kit";
import deleteSvg from "../../../assets/svg/delete.svg";
import "./index.scss";
import {
  deleteApprovalRound,
  deleteColor,
  deleteFiles,
  deleteFolders,
  deleteHyperProjects,
  deleteMyTemplates,
  deleteProjects,
  deleteVariant,
  removeColor,
  setApprovalStatus,
  setFolders,
  setProject,
  setProjects,
  setSelectedColors,
  setSelectedProject,
} from "../../../store/actions";
import { SuccessScreen } from "../../shared/SuccessScreen";
import { useDispatch, useSelector } from "react-redux";
import { ApprovalState, FolderState, LibraryState } from "../../../store/types";
import { openToast } from "../../../Toasts";
import { useHistory } from "react-router-dom";

interface DeleteDrawerInterface {
  title: string;
  subTitle: string;
  isDeleteDrawerVisible: boolean;
  componentCaller: string;
  projectType?: string;
  currentProject?: any;
  setCurrentProject?: Function;
  setDrawerVisible: Function;
  roundId?: number;
  handleStatusChange?: Function;
}

export const DeleteAlert: FC<DeleteDrawerInterface> = ({
  title,
  subTitle,
  isDeleteDrawerVisible,
  componentCaller,
  projectType,
  currentProject,
  setCurrentProject,
  setDrawerVisible,
  roundId,
  handleStatusChange,
}) => {
  const state = useSelector(
    (state: { approval: ApprovalState }) => state.approval
  );
  const folderstate = useSelector(
    (state: { folders: FolderState }) => state.folders
  );
  const librarystate = useSelector(
    (state: { library: LibraryState }) => state.library
  );

  const { approvalStatus } = state;
  const { selectedColors, selectedTemplates } = librarystate;
  const { banners, selectedProjects, selectedFolders, folders } = folderstate;
  const deleteRef = useRef<any>(null);
  const dispatch = useDispatch();
  const rounds = approvalStatus?.rounds || [];
  const [drawerLoader, setDrawerLoader] = useState(false);
  const history = useHistory();

  //Deletion
  const afterDelete = (projects: any, currentFolders: any = []) => {
    dispatch(setProjects(projects));
    if(currentFolders.length){
        dispatch(setFolders(currentFolders));

    }
    dispatch(setSelectedProject([]));
    setDrawerVisible("");
    setCurrentProject && setCurrentProject([]);
  };

  const handleDelete = () => {
    setDrawerLoader(true);
    // single delete
    if (currentProject && componentCaller === "projectDetail") {
      dispatch(
        deleteVariant(
          { project_id: currentProject.id, project_size: selectedProjects },
          (res: any, err: boolean) => {
            if (!err) {
              let project: any = banners.filter(
                (obj: any) => selectedProjects.indexOf(obj.id) === -1
              );
              dispatch(setProject(project));
              dispatch(setSelectedProject([]));
              openToast("success", "Project deleted");
              history.goBack();
            }
          }
        )
      );
    }
    if (currentProject !== undefined) {
      if (componentCaller === "folder") {
        dispatch(
          deleteFolders(currentProject.id, (res: any, err: boolean) => {
            setDrawerLoader(false);
            setDrawerVisible("");
            setCurrentProject && setCurrentProject([]);
          })
        );
      } else if (componentCaller === "approvalDelete" && roundId) {
        dispatch(
          deleteApprovalRound(
            { entity_id: parseInt(currentProject), round_id: roundId },
            (response: any, error: boolean) => {
              if (!error) {
                let round = rounds.filter((round: any) => round.id !== roundId);
                let data = {
                  ...approvalStatus,
                  rounds: round,
                };
                dispatch(setApprovalStatus(data));
                setDrawerLoader(false);
              }
            }
          )
        );
        setDrawerLoader(false);
        setDrawerVisible("");
      }
    } else if (componentCaller === "rejectArtwork") {
      // Drawer loader and visiblity state changed from projectContainer
      handleStatusChange("rejected", setDrawerLoader, setDrawerVisible);
    } else {
      // Top Filter
      if (componentCaller === "project") {
        switch (projectType) {
          case "file":
            dispatch(
              deleteFiles(selectedProjects, (res: any, err: boolean) => {
                if (!err) {
                  let projects: any = banners.filter(
                    (obj: any) => selectedProjects.indexOf(obj.id) === -1
                  );
                  let currentFolders: any = folders.filter(
                    (obj: any) => selectedFolders.indexOf(obj.id) === -1
                  );
                  afterDelete(projects, currentFolders);
                }
                setDrawerLoader(false);
              })
            );
            break;
          case "project":
            console.log("selectedProjects", selectedProjects, folderstate);
            dispatch(
              deleteProjects(
                [...selectedProjects, ...selectedFolders],
                (res: any, err: boolean) => {
                  if (!err) {
                    let projects: any = banners.filter(
                      (obj: any) => selectedProjects.indexOf(obj.id) === -1
                    );
                    let currentFolders: any = folders.filter(
                      (obj: any) => selectedFolders.indexOf(obj.id) === -1
                    );
                    afterDelete(projects, currentFolders);
                  }
                  setDrawerLoader(false);
                }
              )
            );
            break;
          case "hpproject":
            dispatch(
              deleteHyperProjects(
                selectedProjects.length > 1
                  ? selectedProjects
                  : selectedProjects[0],
                (res: any, err: boolean) => {
                  setDrawerLoader(false);
                  if (!err) {
                    let projects: any = banners.filter(
                      (obj: any) => selectedProjects.indexOf(obj.id) === -1
                    );
                    openToast("success", "Projects deleted successfully");
                    let currentFolders: any = folders.filter(
                      (obj: any) => selectedFolders.indexOf(obj.id) === -1
                    );
                    afterDelete(projects, currentFolders);
                  }
                  setDrawerLoader(false);
                }
              )
            );
            break;
          case "color":
            dispatch(
              deleteColor(selectedColors, (res: any, err: boolean) => {
                if (!err) {
                  dispatch(removeColor(selectedColors));
                  dispatch(setSelectedColors([]));
                  setDrawerVisible("");
                  openToast(
                    "success",
                    `Color${selectedColors.length > 1 ? "s" : ""} deleted!`
                  );
                }
                setDrawerLoader(false);
              })
            );
            break;
          case "template":
            dispatch(
              deleteMyTemplates(selectedTemplates, (res: any, err: boolean) => {
                if (!err) {
                  let projects: any = banners.filter(
                    (obj: any) => selectedTemplates.indexOf(obj.id) === -1
                  );
                  afterDelete(projects);
                }
                setDrawerLoader(false);
              })
            );
            break;
        }
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      deleteRef.current!.focus();
    }, [50]);
  }, [deleteRef]);

  const close = () => {
    setDrawerVisible("");
    setCurrentProject && setCurrentProject([]);
  };
  return (
    <Drawer
      className='delete-drawer'
      visible={isDeleteDrawerVisible}
      closable={false}
      onClose={close}
      footer={
        <div className='delete-footer'>
          <Button type='link' className='delete-cancel' onClick={close}>
            Cancel
          </Button>
          <Button
            type='primary'
            className='delete'
            onClick={handleDelete}
            ref={deleteRef}
            loading={drawerLoader}
          >
            <span>
              {componentCaller === "rejectArtwork" ? "Reject" : "Delete"}
            </span>
          </Button>
        </div>
      }
    >
      <SuccessScreen title={title} subTitle={subTitle} successImg={deleteSvg} />
    </Drawer>
  );
};
