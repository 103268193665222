import axios from 'axios';
import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import { DESIGN_URI } from '../../utilities/paths';
import { ExportProject,EXPORT_PROJECT, ExportMultiple, EXPORT_MULTIPLE } from '../types';
import HttpService from '../../HttpService/HttpService';
import { exportAPI, exportOriginal } from '../../MappedAPI/MappedAPI';
import { store } from '../store';
import { openToast } from '../../Toasts';

const getTokenOrPublic = (state: any) => state.authentication.token || state.publicLink.token;

function* exportProject(action: ExportProject) {
    let { payload,callback } = action;
    const TOKEN:any = yield select(getTokenOrPublic);
    try{
      let response = yield call(HttpService.post,DESIGN_URI,`${exportAPI}`,TOKEN,payload)
      if (!response.data.error) {
        callback && callback(response.data,false);
        openToast('success',response?.data?.data?.message || "Project exporting started")
      } else {
        callback && callback(response, true);
      }
    } catch (error) {
      callback && callback(error.response, true);
      openToast('error',error?.response?.data?.message || "Project exporting failed")
    }
}

function* exportMultiple(action: ExportMultiple) {
  console.log('export multiple saga level')
  let { payload,callback } = action;
  const TOKEN:any = yield select(getTokenOrPublic);
  try{
    let response = yield call(HttpService.post,DESIGN_URI,`${exportOriginal}`,TOKEN,payload)
    if (!response.data.error) {
      callback && callback(response.data,false);
      openToast('success',response?.data?.data?.message || "Project exporting started")
    } else {
      callback && callback(response, true);
    }
  } catch (error) {
    callback && callback(error.response, true);
    openToast('error',error?.response?.data?.message || "Project exporting failed")
  }
}

export function* exportWatcher() {
    yield takeLatest(EXPORT_PROJECT, exportProject)
}

export function* exportMultipleWatcher() {
  yield takeLatest(EXPORT_MULTIPLE, exportMultiple)
}