import { getUpdatedCanvasObjectSingle } from "../../../../../ConversionContext/ConversionContext";
import { RESIZE_TYPE } from "./constants";
import { cloneDeep } from "lodash";
import { getObjListSortedByZIndex, sortBySquareDesc } from "./helpers/objectListSort";
import { getUpdatedCanvasBackgroundNew, resizeAllExistBackgrounds, isBackgroundObj } from "./backgroundResize";
import { resizeObjectListSimple, resizeObjectSimple } from "./resize/simpleResize";
import { resizeObjectListFromExtreme } from "./resize/extremeToNormalResize";
import { resizeChildrenExtreme } from "./resize/normalToExtremeResize";
import { getObjectSize } from "./helpers/getObjectSize";
import { getObjectListFromBoundaries } from "./helpers/getObjectListFromBoundaries";
import { getObjectListBoundaries } from "./helpers/getObjectBoundaries";
import { addClassToObjectList } from "./classification/addClassesToObjects";
import { isGroup, getGroupType } from "./grouping/isGroup";
import { getFlatStructure } from "./grouping/getFlatStracture";
// import { getDividedGroups } from "./grouping/recalculateParentSize";
import { IGroup } from "./grouping/types";
import { isBackgroundPart } from "./helpers/isBackgroundPart";
import { recalculateBackgroundPartParent, getFakeBackgroundPart } from "./grouping/recalculateParentSize";
import { isObjectInside, getNearestBackgroundPart } from "./helpers/positioning";
import { resizeBackgroundParts } from "./resize/resizeBackgroundPart";
import { createGroup } from "./grouping/createGroup";
import * as Sentry from "@sentry/react";


export const resizeModule = (
  children: any[],
  backgroundImage: any,
  prevWidth: number,
  prevHeight: number,
  canvasWidth: number,
  canvasHeight: number,
  fabricCanvas?: any
) =>
  new Promise((resolve, reject) => {
    
    let newLogic = true;
    if (localStorage.getItem('useOldResize') === 'true') newLogic = false;

    if (newLogic) {
      resolve({
        children: resizeCanvasChildren(children, prevWidth, prevHeight, canvasWidth, canvasHeight, fabricCanvas),
        backgroundImage: backgroundImage
          ? getUpdatedCanvasBackgroundNew(backgroundImage, prevWidth, prevHeight, canvasWidth, canvasHeight)
          : undefined
      });
    } else {
      resolve({
        children: children.map((obj: any) => getUpdatedCanvasObjectSingle(obj, prevWidth, prevHeight, canvasWidth, canvasHeight)),
        backgroundImage: backgroundImage ? getUpdatedCanvasObjectSingle(backgroundImage, prevWidth, prevHeight, canvasWidth, canvasHeight) : undefined
      });
    }
  });

const getGroups = (children: Array<any>, canvasWidth: number, canvasHeight: number) => {
  let sortedChildren = cloneDeep(children).sort(sortBySquareDesc);

  let groups: any = {};
  let index = 0;

  for (const child of sortedChildren) {
    groups[child?.id] = [];
  }

  while (index < sortedChildren.length) {
    const currentObj = sortedChildren[index];
    const restObjectList = sortedChildren.slice(index + 1, sortedChildren.length);

    for (const parentObj of restObjectList) {
      const groupingType = getGroupType(parentObj, currentObj);
      const isGrouped = isGroup(groupingType);
      if (isGrouped) {
        const prevChildren = groups[parentObj.id]?.children || [];
        const { parent, children } = createGroup(currentObj, parentObj, prevChildren, groupingType);
        groups[parent.id] = {
          parent,
          children
        };
        break;
      }
    }

    index++;
  }

  const result = getFlatStructure(groups, sortedChildren);
  return result;
};

const divideParentsByBackgrounds = (backgroundParts: Array<any>, parents: Array<any>, prevWidth: number, prevHeight: number) => {
  const backgroundPartsModified = [];

  // Calc visible width and height for background parts
  for (const part of backgroundParts) {
    backgroundPartsModified.push(recalculateBackgroundPartParent(part, prevWidth, prevHeight));
  }

  backgroundPartsModified.push(getFakeBackgroundPart(backgroundPartsModified, prevWidth, prevHeight));

  const backgroundPartGroups: any = {};

  for (const partModified of backgroundPartsModified) {
    backgroundPartGroups[partModified.id] = { parent: partModified, children: [] };
  }

  // Divide parents on background parts
  for (const parent of parents) {
    const bpId = getNearestBackgroundPart(parent, cloneDeep(backgroundPartsModified));
    backgroundPartGroups[bpId].children.push(parent);
  }
  return backgroundPartGroups;
};

const resizeGroupChildren = (groupChildren: any, prevParent: any, resizedParent: any) => {
  const [prevParentWidth, prevParentHeight] = getObjectSize(prevParent);
  const [parentWidth, parentHeight] = getObjectSize(resizedParent);

  const result = [];
  if (Array.isArray(groupChildren) && groupChildren.length !== 0) {
    for (const child of groupChildren) {
      const newChild = {
        ...child,
        left: child.left - prevParent.left,
        top: child.top - prevParent.top
      };
      const resizedChild = resizeObjectSimple(newChild, prevParentWidth, prevParentHeight, parentWidth, parentHeight);
      resizedChild.left += resizedParent.left;
      resizedChild.top += resizedParent.top;
      result.push({ ...resizedChild });
    }
  }
  return result;
};

const resizeBackgroundChildren = (groupChildren: any, prevParent: any, resizedParent: any, resizeFunc: any) => {
  const prevParentWidth = prevParent?.visibleWidth;
  const prevParentHeight = prevParent?.visibleHeight;
  const [parentWidth, parentHeight] = resizedParent?.resultSize;

  const tmp = [];
  const result = [];
  if (Array.isArray(groupChildren) && groupChildren.length !== 0) {
    for (const child of cloneDeep(groupChildren)) {
      let newChild = cloneDeep(child);
      newChild = {
        ...newChild,
        left: newChild.left - prevParent.left,
        top: newChild.top - prevParent.top
      };
      tmp.push(newChild);
    }

    const resizedChildren = resizeFunc(tmp, prevParentWidth, prevParentHeight, parentWidth, parentHeight);
    for (const resizedChild of resizedChildren) {
      resizedChild.left += resizedParent.left;
      resizedChild.top += resizedParent.top;
      result.push({ ...resizedChild });
    }
  }
  return result;
};

const resizeGroup = (group: IGroup, prevWidth: number, prevHeight: number, canvasWidth: number, canvasHeight: number, resizeFunc: any) => {
  const result = [];
  const parents = Object.keys(group).map(key => group[key]?.parent);
  const resizedParents = resizeFunc(parents, prevWidth, prevHeight, canvasWidth, canvasHeight);
  result.push(...resizedParents);

  // Resize and position children inside parent (parent = canvas for children)
  for (const parent of parents) {
    const resizedParent = resizedParents.find((p: any) => p?.id === parent?.id);
    const children = group?.[parent.id]?.children || [];
    const resizedGroupChildren = resizeGroupChildren(children, parent, resizedParent);
    result.push(...resizedGroupChildren);
  }

  return result;
};

const getParentResizeFunc = (resizeType: RESIZE_TYPE) => {
  switch (resizeType) {
    case RESIZE_TYPE.SIMPLE:
      return resizeObjectListSimple;
    case RESIZE_TYPE.FROM_EXTREME:
      return resizeObjectListFromExtreme;
    case RESIZE_TYPE.TO_EXTREME:
      return resizeChildrenExtreme;
    default:
      return resizeChildrenExtreme;
  }
};

const getResizeType = (prevWidth: number, prevHeight: number, width: number, height: number) => {
  const resizeRatio = ((width / height) * prevHeight) / prevWidth;
  const isSimpleCrop = resizeRatio > 1 / 3 && resizeRatio < 3;
  if (isSimpleCrop) {
    return RESIZE_TYPE.SIMPLE;
  }

  if ((prevWidth / prevHeight >= 3 || prevWidth / prevHeight <= 1 / 3) && width / height <= 3 && width / height >= 1 / 3) {
    return RESIZE_TYPE.FROM_EXTREME;
  }

  return RESIZE_TYPE.TO_EXTREME;
};

const resizeCanvasChildren = (
  children: Array<any>,
  prevWidth: number,
  prevHeight: number,
  canvasWidth: number,
  canvasHeight: number,
  fabricCanvas: any
) => {
  const initialChildrenLength = children.length;
  // Get new top and left margins for objects (if object is rotated we need to recalculate top and left)
  let childrenBB = getObjectListBoundaries(children);

  addClassToObjectList(childrenBB, prevWidth, prevHeight, canvasWidth, canvasHeight);
  const { backgrounds, backgroundParts, objects } = divideObjectsByType(childrenBB, prevWidth, prevHeight);
  const groups = getGroups(objects, prevWidth, prevHeight);

  const result: Array<any> = [];
  result.push(...resizeAllExistBackgrounds(backgrounds, prevWidth, prevHeight, canvasWidth, canvasHeight));
  const resizeType = getResizeType(prevWidth, prevHeight, canvasWidth, canvasHeight);
  const parentsResizeFunc = getParentResizeFunc(resizeType);

  let resizedObjects = [];

  if (!backgroundParts.length) {
    resizedObjects = resizeGroup(groups, prevWidth, prevHeight, canvasWidth, canvasHeight, parentsResizeFunc);
  } else {
    const allParents = Object.values(groups).map(v => v?.parent);
    const backgroundPartGroups = divideParentsByBackgrounds(backgroundParts, allParents, prevWidth, prevHeight);
    const allBackgroundParts = Object.keys(backgroundPartGroups).map(key => backgroundPartGroups[key].parent);
    const resizedBackgroundParts = resizeBackgroundParts(allBackgroundParts, prevWidth, prevHeight, canvasWidth, canvasHeight, resizeType);
    resizedObjects.push(...resizedBackgroundParts);
    for (const partId of Object.keys(backgroundPartGroups)) {
      const part = backgroundPartGroups[partId].parent;
      const resizedPart = resizedBackgroundParts.find((p: any) => p?.id === part?.id);
      const parents = backgroundPartGroups?.[part.id]?.children || [];
      const resizedGroupParents = resizeBackgroundChildren(parents, part, resizedPart, parentsResizeFunc);
      for (const parent of parents) {
        const resizedParent = resizedGroupParents.find(p => p.id === parent.id);
        const resizedChildren = resizeGroupChildren(groups[parent?.id]?.children, parent, resizedParent);
        resizedObjects.push(...resizedChildren);
      }
      resizedObjects.push(...resizedGroupParents);
    }
  }

  result.push(...resizedObjects);

  const sortedChildren = getObjListSortedByZIndex(childrenBB, result);

  const updatedLeftAndTopChildren = getObjectListFromBoundaries(sortedChildren);

  const resultLength = updatedLeftAndTopChildren.length

  if (initialChildrenLength > resultLength) {
    console.log('missing layers')
    const missingChildren = children.filter(obj => !updatedLeftAndTopChildren.find(resObj => resObj.id === obj.id))

    updatedLeftAndTopChildren.push(...missingChildren);
    Sentry.captureException(new Error("Layers were lost while resize"), {
      tags: {
        prevWidth,
        prevHeight,
        canvasWidth,
        canvasHeight,
      }
    });
  }

  return updatedLeftAndTopChildren;
};

const divideObjectsByType = (children: Array<any>, prevWidth: number, prevHeight: number) => {
  const backgroundParts = [];
  const objects = [];
  const backgrounds = [];

  for (const child of children) {
    if (isBackgroundPart(child)) {
      backgroundParts.push(child);
    } else if (isBackgroundObj(child, prevWidth, prevHeight)) {
      backgrounds.push(child);
    } else {
      objects.push(child);
    }
  }

  return {
    backgrounds,
    backgroundParts,
    objects
  };
};
